export default class DashboardService {
  constructor($q, $rootScope, Authorization, User, Application) {
    this.$q = $q;
    this.$rootScope = $rootScope;
    this.Authorization = Authorization;
    this.User = User;
    this.Application = Application;
  }

  getUsers = () => {
    let defer = this.$q.defer();
    this.User.find({
      filter: {
        where: {
          blocked: false,
        },
        include: "groups",
      },
    })
      .$promise.then((r) => defer.resolve(r))
      .catch((e) => defer.reject(e));
    return defer.promise;
  };

  getApplications = () => {
    let defer = this.$q.defer();

    this.Application.find({
      filter: {
        fields: {
          id: true,
          system: true,
        },
      },
    })
      .$promise.then((r) => {
        let systems = [];
        r.forEach((a) => {
          systems.push(...a.system);
        });
        systems = new Set([...systems]);
        let a = [];
        systems.forEach((d) => {
          let ob = {
            name: d,
            count: r.filter((a) => a.system.includes(d)).length || 0,
          };
          a.push(ob);
        });
        defer.resolve(a);
      })
      .catch((e) => {
        defer.reject(e);
      });

    return defer.promise;
  };
}

DashboardService.$inject = ["$q", "$rootScope", "AuthorizationService", "User", "Application"];
