export default class ApplicationDetailsController {
  constructor($state, $http, UIService, ApplicationService) {
    this.$state = $state;
    this.$http = $http;
    this.UI = UIService;
    this.Application = ApplicationService;
    this.url = {};

    if ($state.params.id != null && $state.params.id != "") {
      this.id = $state.params.id;
      this.isNewInstance = false;
      this.loadData();
    } else {
      // Means we creating a new one
      this.isNewInstance = true;
      this.data = {};
      this.loaded = true;
    }
  }

  loadData = () => {
    this.loaded = false;
    this.Application.get(this.id)
      .then((r) => {
        this.loaded = true;
        this.data = r;
        if (this.isWeb() && this.data.url) {
          this.data.url.forEach((url) => {
            this.getStatus(url);
          });
        }
        this.version = r.versions.length > 0 ? r.versions[0] : {};
      })
      .catch((e) => {
        this.UI.addToast("Ocorreu um erro ao carregar dados da aplicação");
      });
  };

  getStatus = (url) => {
    this.$http.get(`${url.value}/api/status`).then((r) => {
      this.url[url.name] = r.data;
    });
  };

  getVersionCode = (version) => {
    let parts = version.version.match(/.{1,3}/g) || [];
    parts = parts.map((r) => Number(r));
    return parts.join(".");
  };

  generateDoc = () => {
    let list = this.data.versions;
    this.UI.showDialog({
      template: require('./picker.dialog.html'),
      controller: [
        "$scope",
        "$filter",
        ($scope, $filter) => {

          $scope.getVersionCode = (version) => {
            let parts = version.version.match(/.{1,3}/g) || [];
            parts = parts.map((r) => Number(r));
            return parts.join(".");
          };

          // Arrays of data
          $scope.list = list || [];
          // Search filter
          $scope.filter = "";
          $scope.all = false;
          $scope.$watch(() => {
            return $scope.list;
          }, (val) => {
            console.log(val);
          });
          // Pagination
          $scope.pagination = {
            perPage: 10,
            current: 0,
            previous: () => {
              if ($scope.pagination.current > 0) $scope.pagination.current--;
            },
            onFilter: () => {
              const filteredArray = $filter("filter")(
                $scope.list,
                $scope.filter
              );
              const afterFilter =
                Math.ceil(filteredArray.length / $scope.pagination.perPage) - 1;
              if (afterFilter < $scope.pagination.current) {
                $scope.pagination.current = afterFilter;
              }
            },
            next: () => {
              if ($scope.pagination.current < $scope.pagination.total() - 1)
                $scope.pagination.current++;
            },
            total: () => {
              const filteredArray = $filter("filter")(
                $scope.list,
                $scope.filter
              );
              return Math.ceil(
                filteredArray.length / $scope.pagination.perPage
              );
            },
          };

          $scope.select = function (obj) {
            obj.selected = !obj.selected;
          };

          $scope.cancel = function () {
            $scope.$dismiss("cancel");
          };

          $scope.canOk = () => {
            let selected = $scope.list.find((r) => r.selected);
            return selected != null;
          };

          $scope.ok = () => {
            let selected = $scope.list.filter((r) => r.selected);
            $scope.$close(selected);
          };
        },
      ],
    }).then(r => {
      if (r.length > 10) {
        r = r.slice(0, 10);
      }
      let versions = r.map(r => r.version);
      window.open(`/api/applications/release?id=${this.id}&versions=${versions}`, "_blank");
    })
  }

  selectVersion = (version) => {
    this.version = version;
  };

  isSelected = (version) => {
    return JSON.stringify(this.version).includes(JSON.stringify(version));
  };

  save = () => {
    this.Application.save(this.data).then((r) => {
      console.log(this.isNewInstance);
      if (this.isNewInstance) {
        this.UI.addToast("Dados adicionados com sucesso");
        this.$state.go("app.application.details", { id: r.id });
      } else {
        this.UI.addToast("Dados atualizados com sucesso");
      }
    });
  };

  isWeb = () => {
    return this.data && this.data.system && this.data.system.includes("Web");
  };

  isMobile = () => {
    return (
      this.data &&
      this.data.hasOwnProperty("system") &&
      this.data.system &&
      (this.data.system.includes("Mobile") ||
        this.data.system.includes("Android") ||
        this.data.system.includes("iOS")
      ));
  };

  packages = () => {
    let packages = angular.copy(this.data.packages) || [];
    this.keyValueDialog("Links para loja", packages, "packages");
  };

  keyValueDialog = (title, label, value) => {
    let initialValue = value != null ? angular.copy(value) : {};
    return this.UI.showDialog({
      template: require("./key-values.html"),
      controller: [
        "$scope",
        ($scope) => {
          $scope.title = title;

          $scope.label = label;

          $scope.row = initialValue;

          $scope.ok = () => {
            $scope.$close($scope.row);
          };

          $scope.cancel = () => {
            $scope.$dismiss();
          };
        },
      ],
    });
  };

  urls = (data) => {
    this.keyValueDialog(
      "URLs",
      {
        name: "Nome",
        value: "Link",
      },
      data
    ).then((r) => {
      this.data.url = this.data.url || [];
      this.data.url.push(res);
    });
  };

  modules = (data) => {
    if (!this.id) {
      this.UI.addToast("Grave o projeto antes de criar módulos");
      return;
    }
    this.keyValueDialog(
      "Módulos",
      {
        name: "Nome",
        value: "Descrição",
      },
      data
    ).then((r) => {
      r.appId = this.id;
      this.Application.saveModule(r).then((res) => {
        if (r.hasOwnProperty("id")) {
          let i = this.data.modules.indexOf(data);
          this.data.modules[i] = res;
          this.UI.addToast(`Módulo alterado com sucesso`);
        } else {
          this.UI.addToast(`Módulo adicionado com sucesso`);
          this.data.modules.push(res);
        }
      });
    });
  };

  details = () => {
    let data = angular.copy(this.data);
    this.UI.showDialog({
      template: require("./details.dialog.html"),
      controller: [
        "$scope",
        "Client",
        ($scope, Client) => {
          $scope.data = data;

          Client.find().$promise.then((r) => {
            $scope.clients = r;
          });

          $scope.tags = [
            "Web",
            "NodeJS",
            "Wordpress",
            "Mobile",
            "Android",
            "iOS",
            "PHP",
          ];

          $scope.ok = () => {
            $scope.$close($scope.data);
          };

          $scope.cancel = () => {
            $scope.$dismiss();
          };
        },
      ],
    }).then((r) => {
      this.data = r;
      this.save();
    });
  };
}

ApplicationDetailsController.$inject = [
  "$state",
  "$http",
  "UIService",
  "ApplicationService",
];
