import angular from "angular";
import { routes } from "./routes";
import ToolsController from "./list/controller";
import ToolsService from "./service";

export default angular
  .module("app.tool", [])
  .config(routes)
  .service("ToolsService", ToolsService)
  .controller("ToolsController", ToolsController).name;
