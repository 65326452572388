export function routes($stateProvider) {

    $stateProvider
        .state('public.package', {
            url: '/appstore/{id:string}?version',
            params: {
                version: {
                    dynamic: true
                },
            },
            template: require('./appstore/view.html'),
            controller: 'PackageController',
            controllerAs: 'vm'
        })  
}

routes.$inject = ['$stateProvider'];