export default class ToolsService {
  constructor($q, $rootScope, Service) {
    this.$q = $q;
    this.$rootScope = $rootScope;
    this.Service = Service;
  }

  getServices = () => {
    let defer = this.$q.defer();
    this.Service.find()
      .$promise.then((r) => defer.resolve(r))
      .catch((e) => defer.reject(e));
    return defer.promise;
  };
}

ToolsService.$inject = ["$q", "$rootScope", "Service"];