import mapboxgl from "mapbox-gl";
require("mapbox-gl/dist/mapbox-gl.css");

export default angular.module("app.interface.map", []).directive("pmdMapbox", [
  "$compile",
  ($compile) => {
    return {
      restrict: "AE",
      scope: {
        options: "=",
        markers: "=",
      },
      link: (scope, element, attrs) => {
        const uuid = (a) =>
          a
            ? (a ^ ((Math.random() * 16) >> (a / 4))).toString(16)
            : ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, uuid);
        let id = `mapbox-${uuid}`;

        mapboxgl.accessToken =
          "pk.eyJ1Ijoic3RyZWFtbGluZSIsImEiOiJja2ZzNXpwYXUwN2NqMnFzODNuZ3hmYWlvIn0.6bz60-lF9f-nZJ3BFb-CnQ";

        let template =
          "<div style='height: 100%; width: 100%; position: relative' id='" +
          id +
          "'></div>";
        element.replaceWith($compile(template)(scope));

        let map = new mapboxgl.Map({
          style: "mapbox://styles/mapbox/light-v10",
          container: id,
          center: scope.options.center,
          zoom: scope.options.zoom || 13,
          maxZoom: 18,
          interactive: true,
        });

        let currentMarkers = [];
        // Watch for marker changes
        scope.$watch(
          () => {
            return scope.markers;
          },
          (array) => {
            currentMarkers.forEach((oldMarker) => {
              oldMarker.remove();
            });
            currentMarkers = [];
            array.forEach((data) => {
              let el = document.createElement("div");
              el.id = `marker-${data.title}`;
              el.className = "marker";

              let marker = new mapboxgl.Marker(el)
                .setLngLat(data.coordinates)
                .setPopup(
                  new mapboxgl.Popup({ offset: 25 }) // add popups
                    .setHTML("<h3>" + data.title + "</h3>")
                )
                .addTo(map);
              currentMarkers.push(marker);
            });
          }
        );

        scope.options.animateCamera = (coordinates) => {
          map.easeTo({
            center: coordinates,
          });
        };

        map.on("click", (_) => {
          if (_.originalEvent.toElement.classList.contains("marker")) {
            let marker = currentMarkers.find((marker) =>
              _.originalEvent.toElement.id.includes(
                angular.element(marker._element).attr("id")
              )
            );
            if (marker) {
              map.easeTo({
                center: [marker._lngLat.lng, marker._lngLat.lat],
              });
            }
          }
        });

        map.on("load", (_) => {});
      },
    };
  },
]).name;
