export function routes($stateProvider) {

  $stateProvider
    .state('app.client', {
      url: '/clients',
      abstract: true,
      template: '<ui-view></ui-view>'
    })
    .state('app.client.list', {
      url: "?{page:int}&{order:string}&{sort:string}&{term:string}",
      template: require('./list/view.html'),
      controller: 'ClientListController',
      controllerAs: 'vm'
    })
    .state('app.client.details', {
      url: '/{id}',
      template: require('./details/view.html'),
      controller: 'ClientDetailsController',
      controllerAs: 'vm'
    })
}

routes.$inject = ['$stateProvider'];
