import uiRouter from "@uirouter/angularjs";

import { routes, routing } from "./authentication.routes";
import LoginController from "./login/controller";
import ForgotPasswordController from "./forgot-password/forgot-password.controller";
import ResetPasswordController from "./reset-password/controller";
import AuthenticationService from "./authentication.service";
import TwoFactorController from "./two-factor/two-factor.controller";
import MagicLinkController from "./redirect/controller";

export default angular
  .module("app.authentication", [uiRouter])
  .config(routes)
  .config(routing)
  .controller("LoginController", LoginController)
  .controller("MagicLinkController", MagicLinkController)
  .controller("ForgotPasswordController", ForgotPasswordController)
  .controller("ResetPasswordController", ResetPasswordController)
  .controller("TwoFactorController", TwoFactorController)
  .service("AuthenticationService", AuthenticationService)
  .directive('ngTimer', function () {
    return {
      restrict: 'A',
      template: "<span>{{minutes}}:{{seconds}}</span>",
      scope: {
        date: '='
      },
      replace: true,
      link: function (scope, element, attrs) {
        scope.minutes = "05";
        scope.seconds = "00";
        var interval = null;

        let date = Math.abs(moment(scope.date).diff(moment(), 'seconds'));

        var endTimer = function () {
          clearInterval(interval);
        }

        var correctInt = function (number) {
          if (number < 10) {
            return "0" + number;
          }
          return number;
        }

        var run = function () {
          interval = setInterval(function () {
            scope.$apply(function () {
              date -= 1;
              scope.minutes = correctInt(parseInt(date % 3600 / 60));
              scope.seconds = correctInt(date % 60);
            })

            if (date === 0) {
              clearInterval(interval);
            }
          }, 700);
        }

        run();
      }
    };
  })
  .directive("valueMatches", [
    "$parse",
    function ($parse) {
      return {
        require: "ngModel",
        link: function (scope, elm, attrs, ngModel) {
          var originalModel = $parse(attrs.valueMatches),
            secondModel = $parse(attrs.ngModel);
          // Watch for changes to this input
          scope.$watch(attrs.ngModel, function (newValue) {
            ngModel.$setValidity(attrs.name, newValue === originalModel(scope));
          });
          // Watch for changes to the value-matches model's value
          scope.$watch(attrs.valueMatches, function (newValue) {
            ngModel.$setValidity(attrs.name, newValue === secondModel(scope));
          });
        },
      };
    },
  ]).name;
