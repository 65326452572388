export function routes($stateProvider) {

  $stateProvider
    .state('app.administration', {
      url: '/administration',
      abstract: true,
      template: '<ui-view></ui-view>'
    })
    .state('app.administration.logging', {
      url: '/logging',
      template: require('./logging/logging.view.html'),
      controller: 'LoggingController',
      controllerAs: 'vm',
      //role: ['controlPanel']
    })
    .state('app.administration.system', {
      url: '/system',
      template: require('./system/system.view.html'),
      controller: 'SystemController',
      controllerAs: 'vm',
      role: ['controlPanel']
    })
    .state('app.administration.settings', {
      url: '/settings',
      template: require('./settings/view.html'),
      controller: 'SettingsController',
      controllerAs: 'vm',
      //role: ['controlPanel']
    })
    .state('app.administration.main', {
      url: '/',
      template: require('./main/main.view.html'),
      controller: 'ManagementController',
      controllerAs: 'vm',
      //role: ['controlPanel']
    })
    .state('app.administration.users', {
      url: '/users',
      abstract: true,
      template: '<ui-view></ui-view>'
    })
    .state('app.administration.users.add', {
      url: '/add',
      template: require('./main/users/add/add.view.html'),
      controller: 'UserAdminController',
      controllerAs: 'vm',
      //role: ['controlPanel']
    })
    .state('app.administration.users.edit', {
      url: '/edit/:id',
      template: require('./main/users/edit/edit.view.html'),
      controller: 'UserEditAdminController',
      controllerAs: 'vm',
      //role: ['controlPanel']

    })
    .state('app.administration.roles', {
      url: '/roles',
      abstract: true,
      template: '<ui-view></ui-view>'
    })
    .state('app.administration.roles.edit', {
      url: '/edit/:id',
      template: require('./main/roles/edit/edit.view.html'),
      controller: 'RoleEditController',
      controllerAs: 'vm',
      //role: ['controlPanel']
    })
    .state('app.administration.roles.add', {
      url: '/add',
      template: require('./main/roles/add/add.view.html'),
      controller: 'RoleAddController',
      controllerAs: 'vm',
      //role: ['controlPanel']
    })
  ;
}

routes.$inject = ['$stateProvider'];
