export default class ColaboratorService {
  constructor($q, $rootScope, User) {
    this.$q = $q;
    this.$rootScope = $rootScope;
    this.User = User;
  }

  getUsers = () => {
    let defer = this.$q.defer();
    this.User.find({
      filter: {
        include: ["userdetails"]
      }
    }).$promise.then((users) => {
      defer.resolve(users)
    }).catch((e) => defer.reject(e));

    return defer.promise;
  };

  getUser = (id) => {
    let defer = this.$q.defer();
    this.User.findById({
      id: id,
      filter: {
        include: ["userdetails"]
      },
    },
    ).$promise.then((user) => {
      defer.resolve(user);
    }).catch((err) => {
      defer.reject(err);
    });

    return defer.promise;
  };
}

ColaboratorService.$inject = ["$q", "$rootScope", "User"];