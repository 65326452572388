export default class DashboardController {
  constructor(Dashboard, $interval, $scope, $state, $window) {
    this.Dashboard = Dashboard;
    this.getData();
    this.$interval = $interval;
    this.aI = 0;
    this.$window = $window;
  }

  getData = () => {
    this.loaders = {
      users: false,
      apps: false,
    };

    this.Dashboard.getUsers().then((r) => {
      this.users = r;
      this.user = this.users[this.aI];
      this.loaders.users = true;
      if (this.userInterval == undefined) {
        this.userInterval = this.$interval(() => {
          this.user = this.users[this.aI];
          this.users.length - 1 <= this.aI ? (this.aI = 0) : this.aI++;
        }, 5000);
      }
    });

    // TODO: Handle permissions
    this.Dashboard.getApplications().then((r) => {
      this.apps = r;
      this.loaders.apps = true;
    });
  };

  getIcons = (icon) => {
    let a = [
      {
        name: "web",
        icon: "web",
      },
      {
        name: "ios",
        icon: "apple",
      },
      {
        name: "android",
        icon: "google-play",
      },
      {
        name: "nodejs",
        icon: "nodejs",
      },
      {
        name: "mobile",
        icon: "cellphone",
      },
      {
        name: "php",
        icon: "language-php",
      },
      {
        name: "flutter",
        icon: "flutter",
      },
      {
        name: "wordpress",
        icon: "wordpress",
      },
      {
        name: "python",
        icon: "language-python",
      },
    ];

    return a.find((r) => r.name.includes(icon)).icon || "";
  };
}

DashboardController.$inject = ["DashboardService", "$interval", "$scope", "$state", "$window"];
