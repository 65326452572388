export default class ContractController {
  constructor($state, ContractService, UIService) {
    this.$state = $state;
    this.Contract = ContractService;
    this.UI = UIService;
    this.filter = "";
    this.property = "";
    this.reverse = true;
    this.loaded = false;
    this.loadData();
  }

  loadData = () => {
    this.loaded = false;
    this.Contract.getContracts()
      .then((contracts) => {
        this.contracts = contracts;
      })
      .then(() => {
        this.loaded = true;
      });
  };

  orderBy = (property) => {
    this.reverse = this.property === property ? !this.reverse : false;
    this.property = property;
    this.loadData();
  };

  clear = () => {
    this.filter = "";
    this.property = "";
    this.reverse = false;
  };

  totalTime = (contract) => {
    if (!contract || !contract.tickets || !contract.tickets.length) return 0;
    console.log(
      contract.tickets.reduce((a, o) => a + (Number(o.time) || 0), 0)
    );
    return contract.tickets.reduce((a, o) => a + (Number(o.time) || 0), 0) / 60;
  };

  calcAmmountTime = (contract) => {
    if (!contract || !contract.tickets || !contract.tickets.length) return 0;

    let total = contract.tickets.reduce((a, o) => a + (Number(o.time) || 0), 0);

    return this.ticketTime(total);
  };

  ticketTime = (time) => {
    time = Number(time || 0);
    var m = time % 60;
    var h = (time - m) / 60;
    return `${("00" + h).slice(-2)}:${("00" + m).slice(-2)}`;
  };

  lastContact = (contract) => {
    if (!contract || !contract.tickets || !contract.tickets.length)
      return "N/D";
    return moment().diff(contract.tickets[0].createdAt, "day") > 30;
  };

  isAlmostExpired = (date) => {
    if (!date) return 0;
    return moment(date).diff(moment(), "month") < 3;
  };

  complete = (contract) => {
    if (!contract.ref) {
      this.UI.showMessage("O contrato não tem referência associada!");
      return;
    }
    this.UI.showConfirm("Marcar contrato como executado?").then((r) => {
      if (r) {
        contract.closed = true;
        this.Contract.save(contract)
          .then((r) => {
            this.UI.addToast("Contrato executado!");
            this.loadData();
          })
          .catch((e) => {
            this.UI.addToast("Ocorreu um erro: " + e);
            this.loadData();
          });
      }
    });
  };

  openContract = (contract) => {
    this.$window.open(`/api/contracts/generate?id=${contract.id}`, "_blank");
  };
}

ContractController.$inject = ["$state", "ContractService", "UIService"];
