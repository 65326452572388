export default class ClientService {
  constructor(
    $q,
    $rootScope,
    AuthenticationService,
    Client,
    Contact,
    Contract,
    Message,
    Attempt,
    Messaging,
    Balance,
    ZammadTicket
  ) {
    this.$q = $q;
    this.$rootScope = $rootScope;
    this.Auth = AuthenticationService;
    this.Client = Client;
    this.Contact = Contact;
    this.Contract = Contract;
    this.Message = Message;
    this.Attempt = Attempt;
    this.Messaging = Messaging;
    this.Balance = Balance;
    this.ZammadTicket = ZammadTicket;
  }

  list = (filter) => {
    let defer = this.$q.defer();
    this.Client.count({
      where: filter.where,
    })
      .$promise.then((c) => {
        this.Client.find({
          filter: filter,
        })
          .$promise.then((l) =>
            defer.resolve({
              total: c.count,
              data: l,
            })
          )
          .catch((e) => defer.reject(e));
      })
      .catch((e) => defer.reject(e));
    return defer.promise;
  };

  sentMessages = (messagingId) => {
    let defer = this.$q.defer();
    this.Message.count({
      where: {
        messagingId: messagingId,
      },
    }).$promise.then((r) => defer.resolve(r));
    return defer.promise;
  };

  getTickets = (tagId, ref) => {
    let defer = this.$q.defer();
    this.Contract.zammad({
      id: id
    }).$promise.then(r => defer.resolve(r)).catch(e => defer.reject(e));
    return defer.promise;
  }

  get = (id) => {
    let defer = this.$q.defer();
    this.Client.findOne({
      filter: {
        where: {
          id: id,
        },
        include: [
          {
            relation: "messaging",
            scope: {
              include: [
                "contract",
                {
                  relation: "balances",
                  scope: {
                    include: "user",
                    order: "date DESC",
                  },
                },
              ],
            },
          },
          "contacts",
          {
            relation: "contracts",
            scope: {
              order: "dateBegin DESC",
              limit: 6,
            },
          },
          "applications",
          "attempts",
        ],
      },
    })
      .$promise.then((r) => defer.resolve(r))
      .catch((e) => defer.reject(e));
    return defer.promise;
  };

  save = (data) => {
    let defer = this.$q.defer();
    this.Client.upsert(data)
      .$promise.then((r) => defer.resolve(r))
      .catch((e) => defer.reject(e));
    return defer.promise;
  };

  saveContact = (data) => {
    let defer = this.$q.defer();
    this.Contact.upsert(data)
      .$promise.then((r) => defer.resolve(r))
      .catch((e) => defer.reject(e));
    return defer.promise;
  };

  saveContract = (data) => {
    let defer = this.$q.defer();
    this.Contract.upsert(data)
      .$promise.then((r) => defer.resolve(r))
      .catch((e) => defer.reject(e));
    return defer.promise;
  };

  saveTicket = (data) => {
    let defer = this.$q.defer();
    this.ZammadTicket.upsert(data)
      .$promise.then((r) => defer.resolve(r))
      .catch((e) => defer.reject(e));
    return defer.promise;
  };

  saveMessaging = (data) => {
    let defer = this.$q.defer();
    this.Messaging.upsert(data)
      .$promise.then((r) => defer.resolve(r))
      .catch((e) => defer.reject(e));
    return defer.promise;
  };

  addBalance = (data) => {
    let defer = this.$q.defer();
    data.userId = this.Auth.getId();
    this.Balance.create(data)
      .$promise.then((r) => defer.resolve(r))
      .catch((e) => defer.reject(e));
    return defer.promise;
  };

  assignContract = (id, list) => {
    let defer = this.$q.defer();
    this.Contract.assign({
      id: id,
      list: list,
    })
      .$promise.then((r) => defer.resolve(r))
      .catch((e) => defer.reject(e));
    return defer.promise;
  };

  saveAttempt = (data) => {
    if (!data.date) {
      data.date = moment();
    }
    let defer = this.$q.defer();
    this.Attempt.upsert(data)
      .$promise.then((r) => defer.resolve(r))
      .catch((e) => defer.reject(e));
    return defer.promise;
  };
}

ClientService.$inject = [
  "$q",
  "$rootScope",
  "AuthenticationService",
  "Client",
  "Contact",
  "Contract",
  "Message",
  "Attempt",
  "Messaging",
  "Balance",
  "ZammadTicket",
];
