export default class LoginController {
  constructor(
    $rootScope,
    $scope,
    $state,
    $window,
    AuthenticationService,
    UIService
  ) {
    this.$rootScope = $rootScope;
    this.$state = $state;
    this.$window = $window;
    this.Authentication = AuthenticationService;
    this.cLogin();
    this.otpcode = "";
    this.UI = UIService;
    this.email = "";
    this.password = "";
    this.error = "";
    this.waiting = false;
    $rootScope.$on("$receivedServerStatus", (evt, data) => {
      this.providers = data.providers.filter((r) => !r.link);
      this.data = data;
      if (!data.environment.includes("production")) {
        this.env = "Ambiente de desenvolvimento";
      }
    });

    $window.addEventListener(
      "message",
      (message) => {
        $scope.$apply(() => {
          let data = message.data;
          if (data.type && data.type === "oauth") {
            if (data.result === true) {
              this.UI.addToast("A redireccionar...");
              location.reload(true);
            } else {
              this.UI.addToast("Impossível autenticar com a sua conta social");
            }
          }
        });
      },
      false
    );
  }

  cSSO = () => {
    this.card = "sso";
    this.cardTitle = "Opções de início de sessão";
  };

  cPass = () => {
    if (this.email.isEmpty()) {
      this.UI.addToast("Introduza o email");
      return;
    }
    this.card = "password";
    this.cardTitle = "Introduzir palavra-passe";
  };

  cOTP = () => {
    if (this.email.isEmpty()) {
      this.UI.addToast("Introduza o email");
      // return;
    }
    this.card = "otp";
    this.cardTitle = "Introduzir código OTP";
  };

  cLogin = () => {
    this.card = "login";
    this.cardTitle = "Iniciar sessão";
  };

  cMailbox = () => {
    this.card = "mailbox";
    this.cardTitle = "Verifique a caixa de entrada";
  };

  magiclink = () => {
    if (this.email.isEmpty()) {
      this.UI.addToast("Introduza um email");
      return;
    }
    this.Authentication.magiclink(this.email).then((r) => {
      this.cMailbox();
    });
  };

  handleOTPkeys = e => {
    let elem = angular.element(e.currentTarget);
    let parent = elem.parent();
    if (e.keyCode == 8 || e.keyCode == 37) {
      let prev = parent.find('input#' + elem.data('previous'));
      if (prev.length) {
        prev.select();
      }
    } else if ((e.keyCode >= 48 && e.keyCode <= 57) || (e.keyCode >= 96 && e.keyCode <= 105) || e.keyCode == 39) {
      if ((e.keyCode >= 48 && e.keyCode <= 57) || (e.keyCode >= 96 && e.keyCode <= 105)) {
        elem.value = e.key;
        let id = e.currentTarget.id.split("-")[1];
        this.otpcode = this.otpcode.replaceAt(id, e.key);
      }
      let next = parent.find('input#' + elem.data('next'));
      if (next.length) {
        next.select();
      } else {
        if (this.otpcode.length == 6) {
          this.performLogin();
        }
      }
    } else {
      elem.value = "";
    }
  }

  canResendOTP = () => {
    if (this.otpInfo == undefined)
      return true;
    return moment().isSameOrAfter(moment(this.otpInfo.valid));
  }

  otp = () => {
    if (this.email.isEmpty()) {
      this.UI.addToast("Introduza um email");
      return;
    }
    this.Authentication.requestOTP(this.email).then((r) => {
      this.UI.addToast("Código OTP enviado. Verifique a sua caixa de entrada/telemóvel");
      this.otpInfo = r;
      this.cOTP();
    });
  };

  createSSO = (provider) => {
    let opt = {};
    if (provider.hasOwnProperty("color")) {
      opt["background-color"] = provider.color;
    }
    if (provider.hasOwnProperty("text")) {
      opt["color"] = provider.text;
    }
    return opt;
  };

  performLogin = () => {
    if (this.card == "login") {
      this.cPass();
      return;
    }
    if (this.card == 'otp') {
      this.waiting = true;
      this.Authentication.validateOTP(this.otpInfo.id, this.otpcode)
        .then((res) => {
          this.waiting = false;
          this.$state.go("app.dashboard");
        })
        .catch((err) => {
          this.waiting = false;
          switch (err.data.error.code) {
            case "LOGIN_FAILED":
              this.UI.addToast("Email e/ou código OTP errados");
              break;
            case "LOGIN_BLOCKED":
              this.UI.addToast("A conta está bloqueada");
              break;
          }
        });
      return;
    }
    this.waiting = true;
    this.Authentication.login(this.email, this.password)
      .then((res) => {
        this.waiting = false;
        if (res.twofactor) {
          this.$state.go("auth.two-factor", {
            allow: true,
          });
        } else {
          this.$state.go("app.dashboard");
        }
      })
      .catch((err) => {
        this.waiting = false;
        switch (err.data.error.code) {
          case "LOGIN_FAILED":
            this.UI.addToast("Email e/ou password errados");
            break;
          case "LOGIN_BLOCKED":
            this.UI.addToast("A conta está bloqueada");
            break;
        }
      });
  };

  handle = (provider) => {
    let width = 650;
    let height = 700;
    let left = screen.width / 2 - width / 2;
    let top = screen.height / 2 - height / 2;
    this.$window.open(`/auth/${provider.name}`, "_self");
  };
}

LoginController.$inject = [
  "$rootScope",
  "$scope",
  "$state",
  "$window",
  "AuthenticationService",
  "UIService",
];
