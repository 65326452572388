export function routes($stateProvider){
    $stateProvider
        .state('app.tools',{
            url: '/tools',
            abstract: true,
            template: '<ui-view></ui-view>'
        })
        .state('app.tools.list',{
            url: '/',
            template: require('./list/view.html'),
            controller: 'ToolsController',
            controllerAs: 'vm'
        })
}

routes.$inject = ['$stateProvider'];