export default class ChangelogController {
  constructor($state, UIService, ChangelogService, $filter) {
    this.$state = $state;
    this.UI = UIService;
    this.Changelog = ChangelogService;
    this.version = "";
    this.filter = $filter;
    this.loaded = false;

    if ($state.params.id != null && $state.params.id != "") {
      this.id = $state.params.id;
      this.loadInfo();
    }

    this.changelogData = {
      data: [],
      filter: '',
      pagination: {
        perPage: 4,
        current: 0,
        previous: () => {
          if (this.changelogData.pagination.current > 0)
            this.changelogData.pagination.current--;
        },
        next: () => {
          if (this.changelogData.pagination.current < this.changelogData.pagination.total() - 1)
            this.changelogData.pagination.current++;
        },
        total: () => {
          return Math.ceil(this.changelogData.data.length / this.changelogData.pagination.perPage);
        }
      }
    };

    this.loadData();
  }

  loadData = () => {
    this.loaded = false;
    this.Changelog.getChangelog().then((changelog) => {
      this.changelogData.data = changelog;
      this.changelogData.data = this.filter('orderBy')(this.changelogData.data, '-version');
      this.loaded = true;
    }).catch((err) => {
      this.UI.addToast(err);
    });
  };

  loadInfo = () => {
    this.loaded = false;
    this.Changelog.getChangelogInfo(this.id).then((changelog) => {
      this.new = changelog;

      let parts = changelog.version.match(/.{1,3}/g) || [];
      parts = parts.map((r) => Number(r));
      this.new.major = parts[0] || 0;
      this.new.minor = parts[1] || 0;
      this.new.patch = parts[2] || 0;

      this.loaded = true;
    }).catch((err) => {
      this.UI.addToast(err);
    });
  };

  save = () => {
    let data = {};

    if (!this.new.date) {
      this.UI.addToast("Insira a data de lançamento");
      return;
    }
    data.date = this.new.date;

    if (!this.new.major || !this.new.minor || !this.new.patch) {
      this.UI.addToast("Insira a versão");
      return;
    }

    data.version = `${("000" + this.new.major)
      .slice(-3)}${("000" + this.new.minor)
        .slice(-3)}${("000" + this.new.patch)
          .slice(-3)}`;

    this.Changelog.getChangelogVersion(data.version).then((changelog) => {
      if (changelog.id == this.id) {
        data.id = this.id;
        data.gitHash = this.new.gitHash;
        data.implemented = this.new.implemented;
        data.improvements = this.new.improvements;
        data.fixes = this.new.fixes;

        this.Changelog.createChangelog(data).then((a) => {
          this.UI.addToast(`Changelog ${a.id ? "atualizado" : "adicionado"}`);
          this.$state.go("app.changelog.list");
        }).catch((err) => {
          if (err) {
            this.UI.addToast("Erro a criar changelog");
            throw (err);
          }
        });
      } else {
        this.UI.addToast("Versão inserida já existe, incremente patch");
        return;
      }
    }).catch((err) => {
      data.gitHash = this.new.gitHash;
      data.implemented = this.new.implemented;
      data.improvements = this.new.improvements;
      data.fixes = this.new.fixes;

      this.Changelog.createChangelog(data).then((a) => {
        this.UI.addToast(`Changelog ${a.id ? "atualizado" : "adicionado"}`);
        this.$state.go("app.changelog.list");
        this.loadData();
      }).catch((err) => {
        if (err) {
          this.UI.addToast("Erro a criar changelog");
          throw (err);
        }
      });
    });
  };
  getVersionCode = (version) => {
    let parts = version.match(/.{1,3}/g) || [];
    parts = parts.map((r) => Number(r));
    return parts.join(".");
  };
}
ChangelogController.$inject = ["$state", "UIService", "ChangelogService", "$filter"];