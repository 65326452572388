export default class IdentityService {
  constructor($q, SSH) {
    this.$q = $q;
    this.SSH = SSH;
  }

  list = () => {
    let defer = this.$q.defer();
    this.SSH.count()
      .$promise.then((c) => {
        this.SSH.find({
          filter: {
            include: 'user'
          }
        })
          .$promise.then((l) =>
            defer.resolve({
              total: c.count,
              data: l,
            })
          )
          .catch((e) => defer.reject(e));
      })
      .catch((e) => defer.reject(e));

    return defer.promise;
  };

  get = (id) => {
    let defer = this.$q.defer();
    this.SSH.findOne({
      filter: {
        where: {
          id: id,
        },
      },
    })
      .$promise.then((r) => defer.resolve(r))
      .catch((e) => defer.reject(e));
    return defer.promise;
  };
}

IdentityService.$inject = ["$q", "SSH"];
