export default class AuthorizationService {
  constructor($rootScope, $q, Rolemap, AuthenticationService) {
    this.$rootScope = $rootScope;
    this.Rolemap = Rolemap;
    this.AuthenticationService = AuthenticationService;
    this.$q = $q;
    this.roles = [];
    this.groups = [];

    $rootScope.$on("$stateChangeStart", () => {
      if (
        AuthenticationService.isAuthenticated() &&
        AuthenticationService.getUser().hasOwnProperty("email")
      )
        this.loadACLs();
    });

    if (
      AuthenticationService.isAuthenticated() &&
      AuthenticationService.getUser().hasOwnProperty("email")
    )
      this.loadACLs();
  }

  getAllowed = () => {
    return this.roles;
  };

  // Obtem lista de todas as funcionalidades presentes nas roles associadas ao user...
  loadACLs = () => {
    const user = this.AuthenticationService.getUser();
    if (_.isEmpty(user)) return;
    let k = [];
    let j = [];
    user.groups = user.groups || [];
    user.groups.forEach((g) => {
      j.push(g.code);
      g.rolegroup.forEach((r) => {
        if (r.active === true) k.push(r.role.name);
      });
    });
    // Important, otherwise we will have an exception in canPerform...
    k.push("$authenticated");
    this.roles = _.uniqBy(k);
    this.groups = _.uniqWith(j, _.isEqual);
  };

  isAuthenticated = () => {
    return (
      this.AuthenticationService.isAuthenticated() && this.roles.length > 0
    );
  };

  // Verifica se o utilizador pode executar conjunto de roles...
  canPerform = (roles) => {
    if (!Array.isArray(roles)) {
      roles = [...roles];
    }
    let obj = {};
    this.roles.forEach((el, index) => {
      obj[el] = index;
    });
    return roles.every((el) => obj[el] !== undefined);
  };

  // Verifica se o utilizador pertence a um(uns) grupo(s)
  belongsTo = (group) => {
    if (!Array.isArray(group)) {
      group = [...group];
    }
    let obj = {};
    this.groups.forEach((el, index) => {
      obj[el] = index;
    });
    return group.every((el) => obj[el] !== undefined);
  };
}

AuthorizationService.$inject = [
  "$rootScope",
  "$q",
  "Rolemap",
  "AuthenticationService",
];
