window.$ = window.jQuery;
/* Ensure async/await funcionality, if we want to use async/await in services */
require("regenerator-runtime/runtime");
/* Angular Dependencies */
import angular from "angular";
let uiRouter = require("@uirouter/angularjs").default;
import config from "./app.config";

/* Client Modules */
import lbServices from "./modules/loopback/lbServices";
import Interface from "./modules/interface";
import Authentication from "./modules/authentication";
import Administration from "./modules/administration";
import Authorization from "./modules/authorization";
import Identity from "./modules/identities";
import { ApplicationInterceptor } from "./app.interceptor";

import angularLocalePT from "angular-i18n/pt-pt";
import ngResource from "angular-resource";
import ngSanitize from "angular-sanitize";
import ngCookies from "angular-cookies";
import ngAnimate from "angular-animate";
import ngMessages from "angular-messages";
import uiSelect from "ui-select";
import chartJS from "angular-chart.js";
import textAngular from "textangular";
import "angular-file-upload";

/* Client modules */
import Application from "./modules/application";
import Client from "./modules/client";

import Colaborator from "./modules/colaborators";
import Contract from "./modules/contracts";
import Tools from "./modules/tools";

import Public from "./modules/public";

import "@uirouter/angularjs/release/stateEvents";
require("@mdi/font/css/materialdesignicons.min.css");
require("ui-select/dist/select.min.css");
moment.locale("pt");

let app = angular.module("app", [
  uiRouter,
  "ui.router.state.events",
  ngResource,
  ngMessages,
  ngSanitize,
  ngAnimate,
  ngCookies,
  uiSelect,
  textAngular,
  "angularFileUpload",
  angularLocalePT,
  chartJS,
  lbServices,
  Interface,
  Authentication,
  Authorization,
  Administration,
  Application,
  Client,
  Identity,
  Colaborator,
  Contract,
  Tools,
  Public
]);

app.config(config);
app.run(ApplicationInterceptor);
app.run([
  "$rootScope",
  ($rootScope) => {
    $rootScope.uiBase = {
      name: "SLT MongoDB",
      description: "Streamline Admin Template - MongoDB",
      version: "2.0.2",
    };
  },
]);

// UI-Select config
app.config([
  "uiSelectConfig",
  function (uiSelectConfig) {
    uiSelectConfig.theme = "bootstrap";
    uiSelectConfig.resetSearchInput = false;
    uiSelectConfig.skipFocusser = true;
  },
]);

// Add refresh shortcut to do an F5 via $state
app.config([
  "$provide",
  function ($provide) {
    $provide.decorator("$state", [
      "$delegate",
      "$stateParams",
      function ($delegate, $stateParams) {
        $delegate.refresh = function () {
          return location.reload(true);
        };
        return $delegate;
      },
    ]);
  },
]);

// ChartJS Provieder
app.config([
  "ChartJsProvider",
  function (ChartJsProvider) {
    Chart.plugins.register({
      afterDatasetsDraw: function (chartInstance, easing) {
        // To only draw at the end of animation, check for easing === 1
        var ctx = chartInstance.chart.ctx;

        chartInstance.data.datasets.forEach(function (dataset, i) {
          var meta = chartInstance.getDatasetMeta(i);
          let ctrl = meta.controller.chart;
          let datalabel = ctrl.options.datalabel;
          if (!meta.hidden) {
            if (datalabel == undefined || datalabel == true) {
              meta.data.forEach(function (element, index) {
                // Draw the text in black, with the specified font
                ctx.fillStyle = "rgb(0, 0, 0)";

                var fontSize = 10;
                var fontStyle = "normal";
                var fontFamily = "Roboto";
                ctx.font = Chart.helpers.fontString(
                  fontSize,
                  fontStyle,
                  fontFamily
                );

                // Just naively convert to string for now
                var dataString = dataset.data[index].toString();

                // Make sure alignment settings are correct
                ctx.textAlign = "center";
                ctx.textBaseline = "middle";

                var padding = 0;
                var position = element.tooltipPosition();
                ctx.fillText(
                  dataString,
                  position.x,
                  position.y - fontSize / 2 - padding
                );
              });
            }
          }
        });
      },
    });
  },
]);

// TextAngular Provider
app.config([
  "$provide",
  function ($provide) {
    $provide.decorator("taOptions", [
      "$delegate",
      function (taOptions) {
        // $delegate is the taOptions we are decorating
        // here we override the default toolbars and classes specified in taOptions.
        taOptions.forceTextAngularSanitize = false; // set false to allow the textAngular-sanitize provider to be replaced
        taOptions.keyMappings = []; // allow customizable keyMappings for specialized key boards or languages
        taOptions.toolbar = [
          ["bold", "italics", "underline"],
          ["ul", "outdent", "indent"],
          ["redo", "undo", "clear"],
          ['html', 'insertImage','insertLink', 'insertVideo', 'wordcount', 'charcount']
        ];
        taOptions.classes = {
          focussed: "",
          toolbar: "btn-toolbar",
          toolbarGroup: "btn-group",
          toolbarButton:
            "btn btn-xs btn-default pmd-btn-flat pmd-ripple-effect",
          toolbarButtonActive: "active",
          disabled: "disabled",
          textEditor: "form-control",
          htmlEditor: "form-control",
        };
        return taOptions; // whatever you return will be the taOptions
      },
    ]);
    // Replace css icons
    $provide.decorator("taTools", [
      "$delegate",
      function (taTools) {
        taTools.bold.iconclass = " mdi mdi-format-bold";
        taTools.italics.iconclass = "mdi mdi-format-italic";
        taTools.underline.iconclass = " mdi mdi-format-underline";
        taTools.ul.iconclass = " mdi mdi-format-list-bulleted";
        taTools.indent.iconclass = " mdi mdi-format-indent-increase";
        taTools.outdent.iconclass = " mdi mdi-format-indent-decrease";
        taTools.undo.iconclass = " mdi mdi-undo";
        taTools.redo.iconclass = " mdi mdi-redo";
        taTools.clear.iconclass = " mdi mdi-format-clear";
        taTools.html.iconclass = " mdi mdi-code-tags";
        return taTools;
      },
    ]);
  },
]);
