import moment from "moment";

export default class ContractService {
  constructor($q, $rootScope, Contract) {
    this.$q = $q;
    this.$rootScope = $rootScope;
    this.Contract = Contract;
  }

  save = data => {
    let defer = this.$q.defer();
    this.Contract.upsert(data).$promise.then(r => defer.resolve(r)).catch(e => defer.reject(e));
    return defer.promise;
  }

  getContracts = () => {
    let defer = this.$q.defer();
    // Split in two queries
    Promise.allSettled([
      this.Contract.find({
        filter: {
          where: {
            closed: false,
            dateEnd: {
              gt: moment()
            },
          },
          include: [
            "client",
            {
              relation: "zammad",
              scope: {
                include: "tickets",
                scope: {
                  order: "number DESC",
                },
              },
            },
          ],
          order: 'dateEnd DESC, dateBegin DESC'
        },
      }).$promise,
      this.Contract.find({
        filter: {
          where: {
            closed: false,
            amount: { neq: 999 },
            dateEnd: {
              lte: moment(),
            },
          },
          include: [
            "client",
            {
              relation: "zammad",
              scope: {
                include: "tickets",
                scope: {
                  order: "number DESC",
                },
              },
            },
          ],
          order: 'dateEnd DESC, dateBegin DESC'
        },
      }).$promise,
      this.Contract.find({
        filter: {
          where: {
            closed: true
          },
          include: [
            "client",
            {
              relation: "zammad",
              scope: {
                include: "tickets",
                scope: {
                  order: "number DESC",
                },
              },
            },
          ],
        }
      }).$promise
    ]).then((v) => {
      console.log(v);
      let open = v[0].value;
      let expired = v[1].value;
      let executed = v[2].value
      open.forEach((c) => {
        if (c && c.zammad && c.zammad.tickets && c.zammad.tickets.length)
          c.zammad.tickets.sort((a, b) =>
            moment(b.createdAt).diff(moment(a.createdAt))
          );
      });
      expired.forEach((c) => {
        if (c && c.zammad && c.zammad.tickets && c.zammad.tickets.length)
          c.zammad.tickets.sort((a, b) =>
            moment(b.createdAt).diff(moment(a.createdAt))
          );
      });
      executed.forEach((c) => {
        if (c && c.zammad && c.zammad.tickets && c.zammad.tickets.length)
          c.zammad.tickets.sort((a, b) =>
            moment(b.createdAt).diff(moment(a.createdAt))
          );
      });
      defer.resolve({
        open: open,
        expired: expired,
        executed: executed
      });
    });
    return defer.promise;
  };

  getContractsNoTicket = () => {
    let defer = this.$q.defer();
    // Split in two queries
    Promise.allSettled([
      this.Contract.find({
        filter: {
          where: {
            dateEnd: {
              gt: moment(),
            },
          },
          include: [
            "client",
          ],
        },
      }).$promise,
      this.Contract.find({
        filter: {
          where: {
            closed: false,
            amount: { neq: 999 },
            dateEnd: {
              lte: moment(),
            },
          },
          include: [
            "client",
          ],
        },
      }).$promise,
    ]).then((v) => {
      let open = v[0].value;
      let expired = v[1].value;

      open.sort((a, b) => a.client.name.localeCompare(b.client.name));
      expired.sort((a, b) => a.client.name.localeCompare(b.client.name));

      defer.resolve({
        open: open,
        expired: expired,
      });
    });
    return defer.promise;
  };
}

ContractService.$inject = ["$q", "$rootScope", "Contract"];