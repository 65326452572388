import angular from "angular";
import { routes } from "./routes";
import ColaboratorListController from "./list/controller";
import ColaboratorService from "./service";

export default angular
  .module("app.colaborators", [])
  .config(routes)
  .service("ColaboratorService", ColaboratorService)
  .controller("ColaboratorListController", ColaboratorListController).name;
