import { routes } from "./routes";
import ApplicationService from "./service";
import ApplicationListController from "./list/controller";
import ApplicationDetailsController from "./details/controller";
import ApplicationVersionController from "./version/controller";
import ApplicationTaskController from "./gantt/controller";
import ApplicationMapController from "./map/controller";

export default angular
  .module("app.application", [])
  .config(routes)
  .service("ApplicationService", ApplicationService)
  .controller("ApplicationListController", ApplicationListController)
  .controller("ApplicationDetailsController", ApplicationDetailsController)
  .controller("ApplicationVersionController", ApplicationVersionController)
  .controller("ApplicationTaskController", ApplicationTaskController)
  .controller("ApplicationMapController", ApplicationMapController).name;
