export default class ClientListController {
  constructor($state, ClientService, UIService) {
    this.$state = $state;
    this.Client = ClientService;
    this.UI = UIService;
    this.filter = {
      page: $state.params.page || 1,
      term: $state.params.term || "",
      sort: "asc",
      order: "name",
    };
    this.property = "";
    this.reverse = true;
    // Load data
    this.loadData();
  }

  loadData = () => {
    this.loaded = false;
    this.$state.go("app.client.list", this.filter, {
      // prevent the events onStart and onSuccess from firing
      notify: false,
      // prevent reload of the current state
      reload: false,
      // replace the last record when changing the params so you don't hit the back button and get old params
      location: "replace",
      // inherit the current params on the url
      inherit: true,
    });
    this.Client.list(this.createFilter())
      .then((r) => {
        this.data = r.data;
        this.total = r.total;
        this.start = r.total > 0 ? (this.filter.page - 1) * 20 + 1 : 0;
        this.end =
          r.total > 0 ? (this.filter.page - 1) * 20 + r.data.length : 0;
        this.loaded = true;
      })
      .catch((e) => {
        this.UI.addToast("Não foi possível carregar clientes");
      });
  };

  orderBy = (property) => {
    this.reverse = this.property === property ? !this.reverse : false;
    this.property = property;
    this.loadData();
  };

  createFilter = () => {
    let or = [];
    let ob = {};
    let prop = "";
    let pattern = "";
    let where = {};
  
    if (!this.filter.term.isEmpty()) {
      // Filter by title
      prop = `name`;
      pattern = {
        like: `.*${this.filter.term}.*`,
        options: "i",
      };
      ob = {};
      ob[prop] = pattern;
      where = ob;
    }
    return {
      where: where,
      limit: 20,
      order: `${this.filter.order} ${this.filter.sort}`,
      skip: (this.filter.page - 1) * 20,
    };
  };

  next = () => {
    if (this.end < this.total) {
      this.filter.page++;
    }
    this.loadData();
  };

  previous = () => {
    if (this.filter.page > 1) {
      this.filter.page--;
    }
    this.loadData();
  };

  clear = () => {
    this.filter.page = this.$state.params.page || 1;
    this.filter.term = "";
    this.filter.sort = "asc";
    this.filter.order = "name";
    this.loadData();
  };

  add = () => {
    let dialog = this.UI.showDialog({
      size: "lg",
      template: require("./client.dialog.html"),
      controller: [
        "$scope",
        ($scope) => {
          $scope.client = {
            active: true,
          };

          $scope.generateCode = function () {
            if ($scope.client.hasOwnProperty("name")) {
              let matches = $scope.client.name.match(/\b(\w)/g); // ['J','S','O','N']
              $scope.client.code = matches.join(""); // JSON
            }
          };

          $scope.ok = () => {
            $scope.$close($scope.client);
          };

          $scope.cancel = () => {
            $scope.$dismiss();
          };
        },
      ],
    });

    dialog.then((res) => {
      if (res) {
        this.Client.save(res)
          .$promise.then(() => {
            this.loadData();
            this.UI.addToast("Cliente adicionado!");
          })
          .catch(() => {
            this.UI.addToast("Ocorreu um erro ao adicionar cliente");
          });
      }
    });
  };
}

ClientListController.$inject = ["$state", "ClientService", "UIService"];
