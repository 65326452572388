export default class UserEditAdminController {
  constructor($rootScope, $state, $stateParams, AdministrationService, AuthenticationService, UIService) {
    this.$rootScope = $rootScope;
    this.$state = $state;
    this.Administration = AdministrationService;
    this.Authentication = AuthenticationService;
    this.UI = UIService;

    this.user = {
      imap: false,
      blocked: false
    };
    this.loaded = false;
    if (angular.isUndefined($stateParams.id)) {
      $state.go('app.administration.main');
    }
    this.loadUserInfo($stateParams.id);

  }

  isNotUser = () => {
    return (this.Authentication.getId() !== this.user.id);
  };

  blockUser = () => {
    let message = 'Deseja ' + ((this.user.blocked ) ? 'des' : '') + 'bloquear utilizador?';
    let confirm = this.UI.showConfirm(message);
    confirm.then(() => {
      // OK
      this.user.blocked = !this.user.blocked;
      user.$save().then(() => {
        let messaging = 'Utilizador ' + ((this.user.blocked ) ? 'des' : '') + 'bloqueado';
        this.UI.addToast(messaging);
      });
    }).catch(() => {
      // Cancel
    });
  };

  removeUser = () => {
    let dialog = this.UI.showConfirm('Deseja remover utilizador do sistema?');
    dialog.then(() => {
      this.Administration.removeUser(this.user).then((result) => {
        this.UI.addToast('Utilizador removido');
        this.$state.go('app.administration.main');
      });
    });
  }

  // Form for user password change
  changePassword = () => {
    let options = {
      template: require("./password.dialog.html"),
      controller: ["$scope", "$dialog", ($scope, $dialog) => {
        $scope.ok = () => {
          $scope.$close({
            newPassword: $scope.newPassword
          });
        };

        $scope.cancel = () => {
          $dialog.dismiss();
        };
      }]
    };
    this.UI.showDialog(options).then((ok) => {
      this.Administration.changePassword(this.user.id, ok.newPassword).then(() => { this.UI.addToast("Password alterada"); }).catch((err) => { this.UI.addToast("Não foi possível alterar password"); });
    }).catch((err) => {
    });
  };

  loadUserInfo = (id) => {
    this.loaded = false;
    this.Administration.getUser(id).then((user) => {
      this.user = user;
      this.loaded = true;
    }).catch(err => {
      this.UI.addToast('Erro ao carregar dados');
      this.$state.go('app.administration.main');
    });
  }
}

UserEditAdminController.$inject = ['$rootScope', '$state', '$stateParams', 'AdministrationService', 'AuthenticationService', 'UIService'];
