import angular from "angular";
import { routes } from "./routes";
import ContractController from "./list/controller";
import ContractService from "./service";

export default angular
  .module("app.contracts", [])
  .config(routes)
  .service("ContractService", ContractService)
  .controller("ContractController", ContractController).name;
