import { routes } from "./interface.routes";

import MultiClamp from "multi-clamp";
import uiRouter from "@uirouter/angularjs";
import pmdTabs from "./directives/tabs/pmdTabs";
import pmdPickers from "./directives/pickers/pmdPickers";
import pmdAlert from "./directives/toasts/pmdAlert";
import pmdAccordion from "./directives/accordion/pmdAccordion";
import pmdProgress from "./directives/progress/pmdProgress";
import pmdDialog from "./directives/dialogs/pmdDialog";
import pmdTooltip from "./directives/others/pmdTooltip";
import pmdAvatar from "./directives/others/pmdAvatar";
import pmdSelect from "./directives/others/pmdSelect";
import pmdMapbox from "./directives/others/pmdMapbox";

import UIService from "./UI.service";
import NotificationService from "./notification.service";
import DashboardService from "./dashboard/service";
import ChangelogService from "./changelog/service";

import LayoutController from "./layout/layout.controller";
import ProfileController from "./profile/profile.controller";
import DashboardController from "./dashboard/controller";
import ChangelogController from "./changelog/controller";
import CreateChangelogController from "./changelog/controller";

import pmdSlider from "./directives/others/pmdSlider";
import pmdImagePreview from "./directives/others/pmdImagePreview";
import pmdCalendar from "./directives/others/pmdCalendar";
import pmdPanel from "./directives/accordion/pmdPanel";
import pmdTransfer from "./directives/others/pmdTransfer";
import pmdGantt from './directives/gantt/pmdGantt2';

export default angular
  .module("app.interface", [
    uiRouter,
    pmdTabs,
    pmdPickers,
    pmdAlert,
    pmdAccordion,
    pmdProgress,
    pmdDialog,
    pmdTooltip,
    pmdAvatar,
    pmdSelect,
    pmdMapbox,
    pmdSlider,
    pmdImagePreview,
    pmdCalendar,
    pmdPanel,
    pmdTransfer,
    pmdGantt
  ])
  .config(routes)
  .controller("LayoutController", LayoutController)
  .controller("ProfileController", ProfileController)
  .controller("DashboardController", DashboardController)
  .controller("ChangelogController",ChangelogController)
  .service("UIService", UIService)
  .service("DashboardService", DashboardService)
  .service("NotificationService", NotificationService)
  .service("ChangelogService",ChangelogService)
  .directive("ngRightClick", function ($parse) {
    return function (scope, element, attrs) {
      var fn = $parse(attrs.ngRightClick);
      element.bind("contextmenu", function (event) {
        scope.$apply(function () {
          event.preventDefault();
          fn(scope, { $event: event });
        });
      });
    };
  })
  .filter("skip", () => {
    return (input, start) => {
      // Limit exists, but skip doesn't...
      start = +start; // Parse to int :)
      return input.splice(start);
    };
  })
  .filter("nl2br", () => {
    return (value) => {
      return String(value).replace(/\n/g, "<br />");
    };
  })
  .filter("moment", function () {
    return function (date, format) {
      if (!moment) {
        console.log("Error: momentJS is not loaded as a global");
        return "!momentJS";
      }
      if (!date) {
        return;
      }
      if (!format) {
        return moment(date).fromNow();
      } else {
        return moment(date).format(format); //in absence of format parameter, return the relative time from the given date
      }
    };
  })
  .filter("dateDiff", () => {
    return (value, preholder) => {
      // Calculate diference from given date to now
      return moment.utc(value).fromNow(preholder);
    };
  })
  .directive("clamp", () => {
    return {
      restrict: "A",
      link: (scope, el, att) => {
        new MultiClamp(el, {
          ellipsis: "...",
          clamp: att.clamp || "auto",
        });
      },
    };
  })
  .directive("notification", [
    "$compile",
    ($compile) => {
      return {
        restrict: "A",
        require: "ngModel",
        link: (scope, element, attrs, ngModel) => {
          ngModel.$render = () => {
            let a = ngModel.$modelValue;

            let icon = "";
            let type = "";
            let who = "";
            let text = "";

            switch (a.event) {
              case "StartTransaction": {
                icon = "mdi-battery-charging-10";
                type = "notification-info";
                who = a.profile.name;
                text = "iniciou um carregamento";
                break;
              }
              case "StopTransaction": {
                icon = "mdi-battery-charging-100";
                type = "notification-info";
                who = a.profile.name;
                text = "finalizou o carregamento";
                break;
              }
              case "ConnectionRestored": {
                icon = "mdi-network-strength-4";
                type = "notification-warning";
                who = a.evse.alias;
                text = "retomou a ligação ao servidor";
                break;
              }
              case "ConnectionLost": {
                icon = "mdi-network-strength-off";
                type = "notification-warning";
                who = a.evse.alias;
                text = "perdeu a ligação ao servidor";
                break;
              }
            }

            let template =
              "<div class='media-left'>\n" +
              "  <i class='mdi " +
              icon +
              " pmd-xs " +
              type +
              "'></i>\n" +
              "</div>\n" +
              "<div class='media-body'>\n" +
              "  <span class='list-group-item-heading'><a>" +
              who +
              "</a>&nbsp;" +
              text +
              "</span>\n" +
              "  <span class='list-group-item-text'>{{ notification.event.timestamp | moment }}</span>\n" +
              "</div>\n";

            element.replaceWith($compile(template)(scope));
          };
        },
      };
    },
  ]).name;
