export default class ClientDetailsController {
  constructor($state, ClientService, UIService) {
    this.$state = $state;
    this.Client = ClientService;
    this.UI = UIService;
    this.id = $state.params.id;
    this.loadData();

    this.contractLimit = 6;
  }

  loadData = () => {
    this.loaded = false;
    this.Client.get(this.id).then((r) => {
      this.loaded = true;
      r.messaging.forEach((service) => {
        this.sentMessages(service);
      });
      this.data = r;
    });
  };

  limitContract = () => {
    this.contractLimit = this.contractLimit == undefined ? 6 : undefined;
  };

  messaging = () => {
    this.UI.showDialog({
      template: require("./messaging.dialog.html"),
      controller: [
        "$scope",
        ($scope) => {
          $scope.data = {
            clientId: this.id,
            balance: 0,
          };

          $scope.contracts = this.data.contracts;

          $scope.ok = () => {
            $scope.$close($scope.data);
          };

          $scope.cancel = () => {
            $scope.$dismiss();
          };
        },
      ],
    }).then((r) => {
      if (r.contract) {
        r.contractId = angular.copy(r.contract.id);
        delete r.contract;
      }
      this.Client.saveMessaging(r).then((a) => {
        this.UI.addToast(
          `Serviço de notificação ${r.id ? "atualizado" : "adicionado"}`
        );
        this.loadData();
      });
    });
  };

  contract = (contract) => {
    contract = contract || {
      clientId: this.id,
    };
    this.UI.showDialog({
      template: require("./contract.dialog.html"),
      controller: [
        "$scope",
        ($scope) => {
          $scope.contract = angular.copy(contract);
          $scope.ok = () => {
            $scope.$close($scope.contract);
          };

          $scope.cancel = () => {
            $scope.$dismiss();
          };
        },
      ],
    }).then((r) => {
      delete r.expanded;
      this.Client.saveContract(r).then((a) => {
        contract = a;
        this.UI.addToast(`Contrato ${r.id ? "atualizado" : "adicionado"}`);
        this.loadData();
      });
    });
  };

  bulkTickets = (row) => {
    this.UI.showDialog({
      template: require("./tickets.dialog.html"),
      controller: [
        "$scope",
        ($scope) => {
          $scope.contract = row;

          $scope.tickets = [{ val: "" }];

          $scope.ok = () => {
            let o = $scope.tickets.filter((r) => r.val && r.val.length > 0);
            $scope.$close(o);
          };

          $scope.add = () => {
            let exists = $scope.tickets.filter(
              (r) => !r.val || r.val.length === 0
            );
            if (exists == null || exists.length == 0) {
              $scope.tickets.push({ val: "" });
            }
          };

          $scope.remove = (idx) => {
            if ($scope.tickets.length === 1) {
              return;
            }
            $scope.tickets.splice(idx, 1);
          };

          $scope.cancel = () => {
            $scope.$dismiss();
          };
        },
      ],
    }).then((r) => {
      // TODO: Associa a tag
      this.Client.assignContract(row.id, r)
        .then((r) => {
          this.UI.addToast("Tickets adicionados ao contrato");
          this.loadData();
        })
        .catch((e) => {
          this.UI.addToast(e);
        });
    });
  };

  sumTime = (contract) => {
    if (!contract || !contract.tickets || !contract.tickets.length) {
      return "00:00";
    }
    let time = contract.tickets.reduce((a, o) => a + (Number(o.time) || 0), 0);
    var m = time % 60;

    var h = (time - m) / 60;

    var HHMM =
      (h < 10 ? "0" : "") +
      h.toString() +
      ":" +
      (m < 10 ? "0" : "") +
      m.toString();
    return HHMM;
  };

  contact = (contact) => {
    contact = contact || {
      clientId: this.id,
    };
    this.UI.showDialog({
      template: require("./contact.dialog.html"),
      controller: [
        "$scope",
        ($scope) => {
          $scope.contact = angular.copy(contact);

          $scope.ok = () => {
            $scope.$close($scope.contact);
          };

          $scope.cancel = () => {
            $scope.$dismiss();
          };
        },
      ],
    }).then((r) => {
      this.Client.saveContact(r).then((a) => {
        contact = a;
        this.UI.addToast(`Contacto ${r.id ? "atualizado" : "adicionado"}`);
        this.loadData();
      });
    });
  };

  attempt = (attempt) => {
    attempt = attempt || {
      clientId: this.id,
    };
    this.UI.showDialog({
      template: require("./attempt.dialog.html"),
      controller: [
        "$scope",
        ($scope) => {
          $scope.attempt = angular.copy(attempt);

          $scope.ok = () => {
            $scope.$close($scope.attempt);
          };

          $scope.cancel = () => {
            $scope.$dismiss();
          };
        },
      ],
    }).then((r) => {
      this.Client.saveAttempt(r).then((a) => {
        attempt = a;
        this.UI.addToast(
          `Oportunidade de negócio ${r.id ? "atualizada" : "adicionada"}`
        );
        this.loadData();
      });
    });
  };

  expand = (row, $evt) => {
    row.expanded = !row.expanded;
    $evt.stopPropagation();
  };

  copyTicket = (ticket) => {
    const string = `https://helpdesk.streamline.pt/#ticket/zoom/${ticket.id}`;
    navigator.clipboard.writeText(string);
    this.UI.addToast("Copiado para a área de clipboard");
  };

  ticketTime = (time) => {
    time = Number(time || 0);
    var m = time % 60;
    var h = (time - m) / 60;
    return `${("00" + h).slice(-2)}:${("00" + m).slice(-2)}`;
  };

  ticket = (row) => {
    row.time = parseInt(row.time) || 0;
    this.UI.showDialog({
      template: require("./ticket.dialog.html"),
      controller: [
        "$scope",
        ($scope) => {
          $scope.states = [
            {
              id: 2,
              name: "Aberto",
            },
            {
              id: 4,
              name: "Fechado",
            },
          ];

          $scope.ticket = angular.copy(row);

          $scope.ok = () => {
            $scope.$close($scope.ticket);
          };

          $scope.cancel = () => {
            $scope.$dismiss();
          };
        },
      ],
    }).then((r) => {
      this.Client.saveTicket(r).then((a) => {
        row = a;
        this.UI.addToast("Ticket atualizado");
        this.loadData();
      });
    });
  };

  exportMessaging = (id) => {
    console.log(id);
    this.UI.showFields([
      {
        label: "Data a exportar",
        type: "range",
        required: true,
        data: {
          from: moment().subtract(1, "month"),
          to: moment(),
        },
      },
    ]).then((r) => {
      window.open(
        `/api/messaging/generate?id=${id}&from=${r[0].from.format(
          "YYYY-MM-DD"
        )}&to=${r[0].to.format("YYYY-MM-DD")}`,
        "_blank"
      );
    });
  };

  sentMessages = (row) => {
    this.Client.sentMessages(row.id).then((r) => {
      row.count = r.count;
    });
  };

  addBalance = (row) => {
    this.UI.showFields([
      {
        label: "Adicionar saldo",
        type: "number",
        required: true,
        data: null,
      },
      {
        label: "Observações",
        type: "text",
        required: false,
        data: null,
      },
    ]).then((r) => {
      let data = {
        messagingId: row.id,
        value: r[0],
        notes: r[1],
        date: moment.utc(),
      };
      this.Client.addBalance(data).then((r) => {
        this.UI.addToast("Saldo adicionado");
        this.loadData();
      });
    });
  };

  copy = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      this.UI.addToast("Copiado para a área de clipboard");
    });
  };

  details = () => {
    let data = angular.copy(this.data);
    this.UI.showDialog({
      template: require("./client.dialog.html"),
      controller: [
        "$scope",
        ($scope) => {
          $scope.client = data;

          $scope.ok = () => {
            $scope.$close($scope.client);
          };

          $scope.cancel = () => {
            $scope.$dismiss();
          };
        },
      ],
    }).then((r) => {
      this.Client.save(r).then((a) => {
        this.data = a;
        this.UI.addToast(`Detalhes ${this.id ? "atualizados" : "adicionados"}`);
        this.loadData();
      });
    });
  };
}

ClientDetailsController.$inject = ["$state", "ClientService", "UIService"];
