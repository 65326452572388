export default class MagicLinkController {
  constructor($state, $rootScope, $cookies, LoopBackAuth, $timeout) {
    let token = $state.params["access-token"];
    let userId = $state.params["user-id"];
    if (!token || !userId) {
      // Prevent people by hitting the url directly
      $state.go("auth.login");
    }
    $rootScope.$on("$receivedServerStatus", (evt, data) => {
      this.providers = data.providers.filter((r) => !r.link);
      this.data = data;
      if (!data.environment.includes("production")) {
        this.env = "Ambiente de desenvolvimento";
      }
    });
    $timeout(() => {
      if (angular.isDefined(token) && angular.isDefined(userId)) {
        LoopBackAuth.currentUserId = userId;
        LoopBackAuth.accessTokenId = token;
        LoopBackAuth.save();
        $timeout(() => {
          location.reload(true);
        }, 500);
      } else {
        this.UI.addToast("Não foi possível validar os dados introduzidos");
        $timeout(() => {
          $state.go("auth.login");
        }, 500);
      }
    }, 2500);
  }
}

MagicLinkController.$inject = [
  "$state",
  "$rootScope",
  "$cookies",
  "LoopBackAuth",
  "$timeout",
];
