export default class PackageController {
  constructor(UI, $state, Application) {
    this.UI = UI;
    this.$state = $state;
    this.id = $state.params.id;
    this.Application = Application;
    this.loaded = false;
    this.init();
    this.limit = 3;
  }

  limitTo = () => {
    if (this.limit) {
      this.limit = null;
    } else {
      this.limit = 3;
    }
  };

  getVersionCode = (version) => {
    let parts = version.version.match(/.{1,3}/g) || [];
    parts = parts.map((r) => Number(r));
    return parts.join(".");
  };

  init = () => {
    this.Application.findOne({
      filter: {
        where: {
          id: this.id,
        },
        include: [
          "modules",
          "client",
          {
            relation: "versions",
            scope: {
              order: "version DESC",
              include: "module",
            },
          },
        ],
      },
    }).$promise.then((data) => {
      this.data = data;
      this.loaded = true;
      this.setVersion(data.versions[0]);
    });
  };

  download = () => {
    let version = this.data.versions[0];
    if (version && version.package) {
      const link = document.createElement("a");
      link.href = `/api/assets/containers/${version.package.container}/files/${version.package.filename}/download`;
      //link.download = "filename";
      link.click();
    }
  };

  setVersion = (version) => {
    let parts = version.version.match(/.{1,3}/g) || [];
    parts = parts.map((r) => Number(r));
    let params = {
      id: this.$state.params.id,
      major: parts[0],
      minor: parts[1],
      patch: parts[2],
    };
    this.version = version;
    this.$state.go("public.package", params, {
      notify: false,
      reload: false,
      location: "replace",
      inherit: true,
    });
  };
}

PackageController.$inject = ["UIService", "$state", "Application"];
