export default class ApplicationListController {
  constructor($state, UIService, ApplicationService) {
    this.$state = $state;
    this.UI = UIService;
    this.Application = ApplicationService;
    this.Application.filterData()
      .then((r) => {
        this.params = r;
        this.filter = {
          page: $state.params.page || 1,
          term: $state.params.term || "",
          sort: $state.params.sort || "asc",
          order: $state.params.order || "name",
          clients: r.clients,
          systems: r.systems,
        };
        this.loadData();
      })
      .catch((e) => {
        this.UI.addToast("Ocorreu um erro ao carregar dados para filtragem");
        this.filterDisabled = true;
        this.loadData();
      });
  }

  orderBy = (property) => {
    if (this.filter.order === property) {
      if (this.filter.sort.includes("asc")) {
        this.filter.sort = "desc";
      } else {
        this.filter.sort = "asc";
      }
    }

    this.filter.order = property;
    this.loadData();
  };

  createFilter = () => {
    let or = [];
    let ob = {};
    let prop = "";
    let pattern = "";
    let systems = this.filter.systems.map((r) => r.id);
    let clientIds = this.filter.clients.map((r) => r.id);
    let where = {};
    if (this.filter.clients.length != this.params.clients.length) {
      where.clientId = {
        inq: clientIds,
      };
    }
    if (this.filter.systems.length != this.params.systems) {
      where.system = {
        inq: systems,
      };
    }

    if (!this.filter.term.isEmpty()) {
      // Filter by title
      prop = `name`;
      pattern = {
        like: `.*${this.filter.term}.*`,
        options: "i",
      };
      ob = {};
      ob[prop] = pattern;
      or.push(ob);
      prop = "description";
      ob = {};
      ob[prop] = pattern;
      or.push(ob);
      where.or = or;
    }
    return {
      where: where,
      limit: 20,
      order: `${this.filter.order} ${this.filter.sort}`,
      skip: (this.filter.page - 1) * 20,
      include: "client",
    };
  };

  next = () => {
    console.log(this.end < this.total);
    console.log(this.end);
    console.log(this.total);
    if (this.end < this.total) {
      this.filter.page++;
    }
    this.loadData();
  };

  previous = () => {
    if (this.filter.page > 1) {
      this.filter.page--;
    }
    this.loadData();
  };

  clear = () => {
    this.filter.page = this.$state.params.page || 1;
    this.filter.term = "";
    this.filter.sort = "asc";
    this.filter.order = "name";
    this.loadData();
  };

  getSystems = (data) => {
    return data.join(", ");
  };

  loadData = () => {
    this.loaded = false;
    this.$state.go("app.application.list", this.filter, {
      // prevent the events onStart and onSuccess from firing
      notify: false,
      // prevent reload of the current state
      reload: false,
      // replace the last record when changing the params so you don't hit the back button and get old params
      location: "replace",
      // inherit the current params on the url
      inherit: true,
    });
    this.Application.list(this.createFilter())
      .then((r) => {
        this.data = r.data;
        this.total = r.total;
        this.start = r.total > 0 ? (this.filter.page - 1) * 20 + 1 : 0;
        this.end =
          r.total > 0 ? (this.filter.page - 1) * 20 + r.data.length : 0;
        this.loaded = true;
      })
      .catch((e) => {
        this.UI.addToast("Não foi possível carregar projectos");
      });
  };

  readableClientFilter = () => {
    if (!this.params) {
      return "";
    }

    if (this.filter.clients.length === this.params.clients.length) {
      return "Todos os clientes selecionados";
    }

    if (this.filter.clients.length === 1) {
      return this.filter.clients[0].name;
    } else {
      return `${this.filter.clients.length} clientes selecionados`;
    }
  };

  filterClients = () => {
    this.UI.showFilter(this.params.clients, "name").then((r) => {
      this.filter.clients = r;
    });
  };

  readableSystemFilter = () => {
    if (!this.params) {
      return "";
    }

    if (this.filter.systems.length === this.params.systems.length) {
      return "Todos os sistemas selecionados";
    }

    if (this.filter.systems.length === 1) {
      return this.filter.systems[0].name;
    } else {
      return `${this.filter.systems.length} sistemas selecionados`;
    }
  };

  filterSystems = () => {
    this.UI.showFilter(this.params.systems, "name").then((r) => {
      this.filter.systems = r;
    });
  };
}

ApplicationListController.$inject = [
  "$state",
  "UIService",
  "ApplicationService",
];
