export function routes($stateProvider) {
  $stateProvider
    .state("app.identity", {
      url: "/identities",
      abstract: true,
      template: "<ui-view></ui-view>",
    })
    .state("app.identity.list", {
      url: "?{page:int}&{order:string}&{sort:string}",
      template: require("./list/view.html"),
      controller: "IdentityListController",
      controllerAs: "vm",
    });
}

routes.$inject = ["$stateProvider"];
