export function routes($stateProvider) {

    $stateProvider
      .state('app.colaborators', {
        url: '/colaborators',
        abstract: true,
        template: '<ui-view></ui-view>'
      })
      .state('app.colaborators.list', {
        url: '/',
        template: require('./list/view.html'),
        controller: 'ColaboratorListController',
        controllerAs: 'vm'
      })
  }
  
  routes.$inject = ['$stateProvider'];
  