export function routes($stateProvider) {
  $stateProvider
    .state("app.application", {
      url: "/applications",
      abstract: true,
      template: "<ui-view></ui-view>",
    })
    .state("app.application.list", {
      url: "?{page:int}&{order:string}&{sort:string}&{term:string}",
      template: require("./list/view.html"),
      controller: "ApplicationListController",
      controllerAs: "vm",
    })
    .state("app.application.details", {
      url: "/:id",
      template: require("./details/view.html"),
      controller: "ApplicationDetailsController",
      controllerAs: "vm",
    })
    .state("app.application.gantt", {
      url: "/:id/gantt",
      template: require("./gantt/view.html"),
      controller: "ApplicationTaskController",
      controllerAs: "vm",
    })
    .state("app.application.map", {
      url: "/assigned",
      template: require("./map/view.html"),
      controller: "ApplicationMapController",
      controllerAs: "vm",
    })
    .state("app.application.changelog", {
      url: "/:id/version/:version",
      template: require("./version/view.html"),
      controller: "ApplicationVersionController",
      controllerAs: "vm",
    });
}

routes.$inject = ["$stateProvider"];
