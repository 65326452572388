export default class ColaboratorListController {
  constructor($state, ColaboratorService, UIService, $filter) {
    this.$state = $state;
    this.Colaborator = ColaboratorService;
    this.UI = UIService;
    this.filter = $filter;
    this.groups = {};
    this.loaded = false;
    this.userData = {
      data: [],
      filter: '',
      pagination: {
        perPage: 12,
        current: 0,
        previous: () => {
          if (this.userData.pagination.current > 0)
            this.userData.pagination.current--;
        },
        next: () => {
          if (this.userData.pagination.current < this.userData.pagination.total() - 1)
            this.userData.pagination.current++;
        },
        total: () => {
          return Math.ceil(this.userData.data.length / this.userData.pagination.perPage);
        }
      }
    };

    this.loadData();
  }

  loadData = () => {
    this.Colaborator.getUsers().then((users) => {
      this.userData.data = users;
      this.loaded = true;
    });
  };

  getPhoneNumber = (phoneNumber) => {
    if (phoneNumber) {
      let phone = phoneNumber.toString();
      let result = [phone.slice(0, 3), ' ', phone.slice(3)].join('');
      result = [result.slice(0, 7), ' ', result.slice(7)].join('');
      return result;
    }
    return phoneNumber;
  };

  showDialog = (userId) => {
    let data = {};
    this.Colaborator.getUser(userId).then((user) => {
      data.user = user;
      this.UI.showDialog({
        template: require("./details.dialog.html"),
        controller: ["$scope", ($scope) => {
          $scope.user = data.user;
          $scope.getPhoneNumber = (phoneNumber) => {
            if (phoneNumber) {
              let phone = phoneNumber.toString();
              let result = [phone.slice(0, 3), ' ', phone.slice(3)].join('');
              result = [result.slice(0, 7), ' ', result.slice(7)].join('');
              return result;
            }
            return phoneNumber;
          };
          $scope.close = () => {
            $scope.$dismiss();
          };
        },
        ],
      }).catch((err) => {
        if (err != "backdrop click") {
          throw (err);
        }
      });
    });
  };
}
ColaboratorListController.$inject = ["$state", "ColaboratorService", "UIService", "$filter"];