export default class IdentityListController {
  constructor($state, UIService, IdentityService) {
    this.$state = $state;
    this.UI = UIService;
    this.Identity = IdentityService;
    this.filter = {
      page: $state.params.page || 1,
      term: $state.params.term || null,
      sort: "asc",
      order: "name",
    };
    this.loadData();
  }

  loadData = () => {
    this.Identity.list().then((r) => {
      this.data = r.data;
      this.total = r.total;
      this.start = r.total > 0 ? (this.filter.page - 1) * 20 + 1 : 0;
      this.end = r.total > 0 ? (this.filter.page - 1) * 20 + r.data.length : 0;
      this.loaded = true;
    });
  };
}

IdentityListController.$inject = ["$state", "UIService", "IdentityService"];
