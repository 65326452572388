export default class ChangelogService {
    constructor($q, $rootScope, Changelog) {
        this.$q = $q;
        this.$rootScope = $rootScope;
        this.Changelog = Changelog;
    }

    getChangelog = () => {
        let defer = this.$q.defer();
        this.Changelog.find().$promise.then((changelog) => {
            defer.resolve(changelog);
        }).catch((err) => {
            defer.reject(err);
        });

        return defer.promise;
    };

    getChangelogInfo = (id) => {
        let defer = this.$q.defer();
        this.Changelog.findOne({
            filter: {
                where: {
                    id: id,
                },
            },
        }).$promise.then((r) => defer.resolve(r))
            .catch((e) => defer.reject(e));

        return defer.promise;
    };

    getChangelogVersion = (version) => {
        let defer = this.$q.defer();
        this.Changelog.findOne({
            filter: {
                where: {
                    version: version,
                },
            },
        }).$promise.then((r) => defer.resolve(r))
            .catch((e) => defer.reject(e));

        return defer.promise;
    };

    createChangelog = (data) => {
        let defer = this.$q.defer();
        this.Changelog.upsert(data).$promise.then((changelog) => {
            defer.resolve(changelog);
        }).catch((err) => {
            defer.reject(err);
        });

        return defer.promise;
    };
}

ChangelogService.$inject = ["$q", "$rootScope", "Changelog"];