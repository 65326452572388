export default class ApplicationVersionController {
  constructor($state, UIService, ApplicationService, FileUploader) {
    this.$state = $state;
    this.id = $state.params.id;
    this.version = $state.params.version;
    let hasVersion = !!$state.params.version;
    this.UI = UIService;
    this.Application = ApplicationService;
    this.FileUploader = FileUploader;
    ApplicationService.versionFilterData(this.id).then((r) => {
      this.filter = r;
    });
    if (hasVersion) {
      this.version = $state.params.version;
      this.isNewInstance = false;
    } else {
      this.loaded = true;
      this.data = {
        appId: this.id,
      };
      this.aux = {
        version: {},
      };
      this.isNewInstance = true;
    }

    this.loadData();

    // Setup uploader

    this.uploader = new FileUploader({
      url: "/api/assets/containers/packages/files",
      queueLimit: 1,
      autoUpload: true,
    });
    this.uploader.onWhenAddingFileFailed = (item, filter) => {
      if (filter.name == "queueLimit") {
        this.uploader.clearQueue();
        this.uploader.addToQueue(item);
      }
    };
    this.uploader.onBeforeUploadItem = (item, filter) => {
      const uuid = (a) =>
        a
          ? (a ^ ((Math.random() * 16) >> (a / 4))).toString(16)
          : ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, uuid);
      item.file.name = `${uuid()}.apk`;
      if (this.data.package) {
        item.file.name = this.data.package.filename;
      }
    };
    this.uploader.onSuccessItem = (item, response) => {
      this.data.package = {
        container: response[0].metadata.container,
        filename: response[0].filename,
        size: response[0].length,
      };
    };
    this.uploader.filters.push({
      name: "apkFilter",
      fn: (item, options) => {
        return item.type.includes("application/vnd.android.package-archive");
      },
    });
  }

  isMobile = () => {
    if (!this.app) {
      return false;
    }
    return (
      (this.app &&
        this.app &&
        this.app.system &&
        this.app.system.includes("Mobile")) ||
      this.app.system.includes("Android") ||
      this.app.system.includes("iOS")
    );
  };

  loadData = () => {
    this.Application.get(this.id)
      .then((app) => {
        this.app = app;
        if (!this.isNewInstance) {
          this.Application.getAppVersion(this.id, this.version).then((r) => {
            if (r.package) {
              // Add dummy file
              var dummy = new this.FileUploader.FileItem(this.uploader, {
                lastModifiedDate: new Date(),
                size: r.package.size,
                type: "application/vnd.android.package-archive",
                name: r.package.filename,
              });

              dummy.progress = 100;
              dummy.isUploaded = true;
              dummy.isSuccess = true;

              this.uploader.queue.push(dummy);
            }
            this.loaded = true;
            let parts = r.version.match(/.{1,3}/g) || [];
            parts = parts.map((r) => Number(r));
            this.aux = {
              version: {
                major: parts[0] || 0,
                minor: parts[1] || 0,
                patch: parts[2] || 0,
              },
            };
            this.data = r;
          });
        } else {
          this.loaded = true;
        }
      })
      .catch((e) => {});
  };

  save = () => {
    if (!this.data.date) {
      this.UI.addToast("Insira a data de lançamento");
      return;
    }
    if (
      !(
        !this.aux ||
        !!this.aux.version ||
        !!this.aux.version.major ||
        !!this.aux.version.minor ||
        !!this.aux.version.patch
      )
    ) {
      this.UI.addToast("Insira a versão");
      return;
    }
    if (!this.data.module) {
      this.UI.addToast("Insira o módulo a que pertence a release");
      return;
    }
    this.data.version = `${("000" + this.aux.version.major).slice(-3)}${(
      "000" + this.aux.version.minor
    ).slice(-3)}${("000" + this.aux.version.patch).slice(-3)}`;
    this.data.moduleId = this.data.module.id;
    this.Application.saveVersion(this.data).then((r) => {
      if (!this.version) {
        this.UI.addToast("Release note adicionada!");
        this.$state.go("app.application.changelog", {
          id: this.id,
          version: r.version,
        });
      } else {
        this.UI.addToast("Release note atualizada");
      }
    });
    return;
    this.Application.validateVersion(this.id, this.data.version)
      .then((r) => {
        if (r.exists) {
          this.UI.addToast(`A versão indicada já foi registada`);
          return;
        }
        if (r.last != undefined && Number(r.last) > Number(this.data.version)) {
          let parts = r.last.match(/.{1,3}/g) || [];
          parts = parts.map((r) => Number(r));
          this.UI.addToast(
            `A versão deverá ser superior à ${parts[0]}.${parts[1]}.${parts[2]}`
          );
          return;
        }
        this.Application.saveVersion(this.data).then((r) => {
          if (!this.version) {
            this.UI.addToast("Release note adicionada!");
            this.$state.go("app.application.version", {
              id: this.id,
              version: r.version,
            });
          } else {
            this.UI.addToast("Release note atualizada");
          }
        });
      })
      .catch((e) => {});
  };
}

ApplicationVersionController.$inject = [
  "$state",
  "UIService",
  "ApplicationService",
  "FileUploader",
];
