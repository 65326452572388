export default class ToolsController {
    constructor($scope, $state, $window, ToolsService, $interval) {
        this.Tools = ToolsService;
        this.$interval = $interval;
        this.aI = 0;
        this.$window = $window;
        this.loaded = false;
        this.getData();
    }

    getData = () => {
        this.loaded = false;
        this.Tools.getServices().then((r) => {
            this.services = r;
            this.loaded = true;
        });
    };

    goTo = service => {
        this.$window.open(service.url, '_blank');
    };
}

ToolsController.$inject = ["$scope", "$state", "$window", "ToolsService", "$interval"];