import { gantt } from "dhtmlx-gantt";

require("./dhtmlxgantt");
require("./material.gantt.css");
//require('./a.css');

export default angular
  .module("app.interface.gantt", [])
  .directive("dhxGantt", function () {
    return {
      restrict: "A",
      scope: {
        users: "=?",
        appId: "=",
      },
      transclude: true,
      template: "<div ng-transclude></div>",

      link: function ($scope, $element, $attrs) {
        $scope.$watch(
          () => {
            return $scope.appId;
          },
          (appId) => {
            if (!appId) {
              throw Error("Application ID is required!");
            }
          }
        );

        $scope.$watch(
          () => {
            return $scope.users;
          },
          (collection) => {
            if (!Array.isArray(collection)) {
              throw Error("Users property must be an array");
            }
          }
        );

        //size of gantt
        $scope.$watch(
          function () {
            return $element[0].offsetWidth + "." + $element[0].offsetHeight;
          },
          function () {
            gantt.setSizes();
          }
        );
        //dp.setTransactionMode("REST");

        //init gantt
        gantt.config.lightbox.sections = [
          {
            name: "description",
            height: 38,
            map_to: "text",
            type: "textarea",
            focus: true,
          },
          { name: "type", type: "typeselect", map_to: "type", height: 38 },
          {
            name: "custom",
            label: "Atribuir",
            options: $scope.users,
            map_to: "user",
            height: 38,
            type: "select",
          },
          { name: "time", height: 72, map_to: "auto", type: "duration" },
        ];

        gantt.config.sort = true;

        gantt.i18n.setLocale("pt");
        gantt.locale.labels.section_custom = "Atribuir";
        gantt.config.order_branch = "marker";
        gantt.config.order_branch_free = true;
        gantt.config.fit_tasks = true;
        gantt.attachEvent("onBeforeTaskMove", function (id, parent, tindex) {
          var task = gantt.getTask(id);
          if (task.parent != parent) return false;
          return true;
        });
        gantt.config.date_format = "%Y-%m-%d";
        gantt.config.scales = [
          { unit: "month", step: 1, format: "%F, %Y" },
          { unit: "day", step: 1, format: "%j, %D" },
        ];

        gantt.init($element[0]);
        gantt.load(`/data/${$scope.appId}`);
        let dp = new gantt.dataProcessor(`/data/${$scope.appId}`);
        dp.init(gantt);
        dp.setTransactionMode("REST");
      },
    };
  })
  .directive("ganttTemplate", [
    "$interpolate",
    function ($interpolate) {
      return {
        restrict: "AE",
        terminal: true,

        link: function ($scope, $element, $attrs, $controller) {
          var interpolated = $interpolate($element.html());

          gantt.templates[$attrs.ganttTemplate] = function (start, end, task) {
            return interpolated({ task: task });
          };
        },
      };
    },
  ])
  .directive("ganttColumn", [
    "$interpolate",
    function ($interpolate) {
      return {
        restrict: "AE",
        terminal: true,

        link: function ($scope, $element, $attrs, $controller) {
          var label = $attrs.label || " ";
          var width = $attrs.width || "*";
          var align = $attrs.align || "left";

          var interpolated = $interpolate($element.html());
          var template = function (task) {
            return interpolated({ task: task });
          };

          var config = {
            template: template,
            label: label,
            width: width,
            align: align,
          };

          if (!gantt.config.columnsSet)
            gantt.config.columnsSet = gantt.config.columns = [];

          if (!gantt.config.columns.length) config.tree = true;
          gantt.config.columns.push(config);
        },
      };
    },
  ])
  .directive("ganttColumnAdd", [
    function () {
      return {
        restrict: "AE",
        terminal: true,
        link: function () {
          gantt.config.columns.push({ width: 45, name: "add" });
        },
      };
    },
  ]).name;
