export default class ApplicationTaskController {
  constructor($state, UIService, ApplicationService, User, $filter) {
    this.$state = $state;
    this.UI = UIService;
    this.Application = ApplicationService;

    this.id = $state.params.id;
    this.task = $state.params.task;

    User.find().$promise.then((users) => {
      this.users = [];
      users.forEach((a) => {
        this.users.push({
          key: a.id,
          label: a.name,
        });
      });
      this.loaded = true;
      this.users = $filter("orderBy")(this.users, "label");
    });
  }
}

ApplicationTaskController.$inject = [
  "$state",
  "UIService",
  "ApplicationService",
  "User",
  "$filter",
];
