export default class ApplicationService {
  constructor(
    $q,
    $rootScope,
    Application,
    Versioning,
    Client,
    Module,
    AuthenticationService
  ) {
    this.$q = $q;
    this.$rootScope = $rootScope;
    this.Application = Application;
    this.Versioning = Versioning;
    this.Client = Client;
    this.Module = Module;
    this.Auth = AuthenticationService;
  }

  versionFilterData = (id) => {
    let defer = this.$q.defer();
    this.Module.find({
      filter: {
        where: {
          appId: id,
        },
      },
    })
      .$promise.then((r) => defer.resolve(r))
      .catch((e) => defer.reject(e));
    return defer.promise;
  };

  filterData = () => {
    let defer = this.$q.defer();
    let a = ["Web", "Android", "iOS", "Wordpress", "NodeJS", "PHP"];
    let systems = a.map((r) => {
      return {
        id: r,
        name: r,
      };
    });
    this.Client.find().$promise.then((r) => {
      defer.resolve({
        clients: r,
        systems: systems,
      });
    });
    return defer.promise;
  };

  list = (filter) => {
    let defer = this.$q.defer();
    this.Application.count({
      where: filter.where,
    })
      .$promise.then((c) => {
        this.Application.find({
          filter: filter,
        })
          .$promise.then((l) =>
            defer.resolve({
              total: c.count,
              data: l,
            })
          )
          .catch((e) => defer.reject(e));
      })
      .catch((e) => defer.reject(e));

    return defer.promise;
  };

  get = (id) => {
    let defer = this.$q.defer();
    this.Application.findOne({
      filter: {
        where: {
          id: id,
        },
        include: [
          "modules",
          "client",
          {
            relation: "versions",
            scope: {
              order: 'version DESC',
              include: "module",
            },
          },
        ],
      },
    })
      .$promise.then((r) => defer.resolve(r))
      .catch((e) => defer.reject(e));
    return defer.promise;
  };

  getAppVersion = (id, version) => {
    let defer = this.$q.defer();
    this.Versioning.findOne({
      filter: {
        where: {
          appId: id,
          version: version,
        },
        include: ["module", "application"],
      },
    })
      .$promise.then((r) => defer.resolve(r))
      .catch((e) => defer.reject(e));
    return defer.promise;
  };

  save = (data) => {
    let defer = this.$q.defer();
    this.Application.upsert(data)
      .$promise.then((r) => defer.resolve(r))
      .catch((e) => defer.reject(e));
    return defer.promise;
  };

  saveModule = (data) => {
    let defer = this.$q.defer();
    this.Module.upsert(data)
      .$promise.then((r) => defer.resolve(r))
      .catch((e) => defer.reject(e));
    return defer.promise;
  };

  saveVersion = (data) => {
    let u = this.Auth.getUser();
    data.userId = u.id;
    let defer = this.$q.defer();
    this.Versioning.upsert(data)
      .$promise.then((r) => defer.resolve(r))
      .catch((e) => defer.reject(e));
    return defer.promise;
  };

  validateVersion = (id, version) => {
    let defer = this.$q.defer();
    this.Versioning.validate({
      id: id,
      version: version,
    })
      .$promise.then((r) => defer.resolve(r))
      .catch((e) => defer.reject(e));
    return defer.promise;
  };

  getTasksByUser = (date) => {
    let defer = this.$q.defer();

    let from = moment.utc(date).subtract(30, "day");
    let to = moment.utc(date).add(30, "day");

    this.Task.find({
      filter: {
        where: {
          start_date: {
            between: [from.format("YYYY-MM-DD"), to.format("YYYY-MM-DD")],
          },
        },
        include: ["application", "userData"],
      },
    })
      .$promise.then((r) => defer.resolve(r))
      .catch((e) => defer.reject(e));
    return defer.promise;
  };
}

ApplicationService.$inject = [
  "$q",
  "$rootScope",
  "Application",
  "Versioning",
  "Client",
  "Module",
  "AuthenticationService",
];
