export function routes($stateProvider) {
    $stateProvider
      .state('app.contracts', {
        url: '/contracts',
        abstract: true,
        template: '<ui-view></ui-view>'
      })
      .state('app.contracts.list', {
        url: '/',
        template: require('./list/view.html'),
        controller: 'ContractController',
        controllerAs: 'vm'
      })
  }
  
  routes.$inject = ['$stateProvider'];
  