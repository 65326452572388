import angular from "angular";
import { routes } from "./routes";
import ClientListController from "./list/controller";
import ClientDetailsController from "./details/controller";
import ClientService from "./service";

export default angular
  .module("app.clients", [])
  .config(routes)
  .directive('enter', () => {
    return (scope, element, attrs) => {
      element.bind("keydown keypress", (event) => {
        if (event.which === 13) {
          scope.$apply(() => {
            scope.$eval(attrs.enter);
          });
          // Change focus if possible
          try {
            if (attrs.tabindex !== undefined) {
              var currentTabeIndex = attrs.tabindex;
              var nextTabIndex = parseInt(currentTabeIndex) + 1;
              var elems = document.querySelectorAll("[tabindex]");
              for (var i = 0, len = elems.length; i < len; i++) {
                var el = angular.element(elems[i]);
                var idx = parseInt(el.attr('tabindex'));
                if (idx === nextTabIndex) {
                  elems[i].focus();
                  break;
                }
              }
            }
          } catch (e) {
          }
          event.preventDefault();
        }
      });
    };
  })
  .directive('backspace', () => {
    return {
      require: "^ngModel",
      link: (scope, element, attrs, ngModel) => {
        element.bind("keydown keypress", (event) => {
          if (event.which === 8) {
            if (!ngModel.$viewValue || ngModel.$viewValue.length === 0) {
              scope.$apply(() => {
                scope.$eval(attrs.backspace);
              });
              // Change focus if possible
              try {
                if (attrs.tabindex !== undefined) {
                  var currentTabeIndex = attrs.tabindex;
                  var previousTabIndex = parseInt(currentTabeIndex) - 1;
                  if (previousTabIndex < 0) previousTabIndex = 0;
                  var elems = document.querySelectorAll("[tabindex]");
                  for (var i = 0, len = elems.length; i < len; i++) {
                    var el = angular.element(elems[i]);
                    var idx = parseInt(el.attr('tabindex'));
                    if (idx === previousTabIndex) {
                      elems[i].focus();
                      break;
                    }
                  }
                }
              } catch (e) {
              }
              event.preventDefault();
            }
          }
        });
      },
    }
  })
  .directive("helpdeskInput", function () {
    return {
      restrict: 'A',
      require: 'ngModel',
      link: function (scope, element, attributes, control) {
        control.$validators.helpdeskInput = (model, input) => {
          if (angular.isUndefined(input)) {
            return true;
          }
          if (control.$isEmpty(model)) {
            return true;
          }
          let urlRegex = /^(https?:\/\/helpdesk\.streamline\.pt\/#ticket\/(zoom\/[0-9]*))$/;
          let ticketRegex = /(\d{4})(0?[1-9]|1[0-2])(\d{8})/;
          return !!input.match(urlRegex) || !!input.match(ticketRegex);
        }
      }

    };
  })
  .service("ClientService", ClientService)
  .controller("ClientListController", ClientListController)
  .controller("ClientDetailsController", ClientDetailsController).name;
