export default class ApplicationMapController {
  constructor($state, $scope, UIService, ApplicationService, $compile) {
    this.$state = $state;
    this.UI = UIService;
    this.Application = ApplicationService;
    this.options = {
      eventRender: (event, element) => {
        let div = "";
        if (event.user != undefined) {
          div =
            '<div class="pmd-card pmd-z-depth" style="margin-bottom: 0">' +
            '<div class="media-body media-middle">' +
            '<h3 class="list-group-item-heading">{{title}}</h3>' +
            '<span class="list-group-item-text">{{app}}</span> </div>' +
            '<div class="media-right"> <a tooltip="Atribuído a {{user.name}}" href="javascript:void(0);" class="avatar-list-img">' +
            '<pmd-avatar size="40" user="user">' +
            "</a> </div>" +
            "</div>";
        } else {
          div =
            '<div class="pmd-card pmd-z-depth" style="margin-bottom: 0">' +
            '<h3 class="list-group-item-heading">{{title}}</h3>' +
            '<span class="list-group-item-text">{{app}}</span>' +
            "</div>";
        }
        let scope = $scope.$new(true);
        scope.user = event.user;
        scope.title = event.title;
        scope.app = event.app;
        let compile = $compile(div)(scope);
        element.find("div.fc-content").replaceWith(compile);
      },
      defaultView: "month",
      locale: "pt",
      themeSystem: "bootstrap4",
      header: {
        left: "prev,next today",
        center: "title",
        right: "month,listMonth",
      },
      navLinks: true,
      allDaySlot: true,
      displayEventEnd: false,
      timeFormat: "HH:mm",
      minTime: "08:00:00",
      maxTime: "22:00:00",
      height: "auto",
      slotDuration: "00:30:00",
      slotLabelFormat: "HH:mm",
      views: {
        agendaWeek: {
          columnHeaderFormat: "DD/MM",
        },
      },
      eventTextColor: "#000000d6",
      firstDay: 1,
      nowIndicator: true,
      weekNumberTitle: "Sem.",
      eventLimitText: "agd.",
      weekNumbers: true,
      eventLimit: true,
      buttonText: {
        today: "Hoje",
        month: "Mensal",
        week: "Semanal",
        day: "Diário",
        list: "Lista",
      },
    };
    this.loadData();
  }

  parseEvents = () => {
    this.events = [];
    this.data.forEach((task) => {
      this.events.push({
        id: task.id,
        start: moment.utc(task.start_date),
        end: moment.utc(task.start_date).add(task.duration, "day"),
        title: `${task.id}: ${task.text}`,
        allDay: true,
        color: "",
        app: task.application.name,
        user: task.userData,
      });
    });
  };

  loadData = () => {
    this.loaded = false;
    this.Application.getTasksByUser(moment.utc())
      .then((r) => {
        this.data = r;
        this.parseEvents();
        this.loaded = true;
      })
      .catch((e) => {
        this.$state.go("app.application.list");
        this.UI.addToast("Ocorreu um erro ao aceder à lista de tarefas");
      });
  };
}

ApplicationMapController.$inject = [
  "$state",
  "$scope",
  "UIService",
  "ApplicationService",
  "$compile",
];
