import angular from "angular";
import modalHelper from "./modalHelper";

export default angular
  .module("app.interface.dialogs", [modalHelper])
  .controller("DialogController", [
    "$scope",
    "$dialog",
    "message",
    function ($scope, $dialog, message) {
      $scope.message = message;

      $scope.ok = () => {
        $dialog.close(true);
      };

      $scope.cancel = () => {
        $dialog.dismiss("cancel");
      };
    },
  ])
  .factory("Dialog", [
    "$rootScope",
    "$modal",
    function ($rootScope, $modal) {
      return {
        waiting: () => {
          return $modal.open({
            keyboard: false,
            backdrop: "static",
            size: "sm",
            template:
              '<div style="padding: 24px 16px 16px 16px;"><pmd-spinner></pmd-spinner></div>',
          });
        },
        alert: (message) => {
          return $modal.open({
            keyboard: true,
            backdrop: "static",
            template: require("./templates/alert.html"),
            controller: "DialogController",
            resolve: {
              message: function () {
                return message;
              },
            },
          }).result;
        },
        confirm: (message) => {
          return $modal.open({
            keyboard: true,
            backdrop: "static",
            template: require("./templates/confirm.html"),
            controller: "DialogController",
            resolve: {
              message: function () {
                return message;
              },
            },
          }).result;
        },
        dialog: (options) => {
          options.size = "lg";
          options.backdrop = "static";
          return $modal.open(options).result;
        },
        filter: (list, property, itemsPerPage) => {
          itemsPerPage = itemsPerPage || 15;
          return $modal.open({
            template: require("./templates/picker.html"),
            backdrop: "static",
            controller: [
              "$scope",
              "$filter",
              ($scope, $filter) => {
                // Arrays of data
                $scope.property = property;
                $scope.list = list || [];
                // Search filter
                $scope.filter = "";
                // Pagination
                $scope.pagination = {
                  perPage: itemsPerPage,
                  current: 0,
                  previous: () => {
                    if ($scope.pagination.current > 0)
                      $scope.pagination.current--;
                  },
                  onFilter: () => {
                    const filteredArray = $filter("filter")(
                      $scope.list,
                      $scope.filter
                    );
                    const afterFilter =
                      Math.ceil(
                        filteredArray.length / $scope.pagination.perPage
                      ) - 1;
                    if (afterFilter < $scope.pagination.current) {
                      $scope.pagination.current = afterFilter;
                    }
                  },
                  next: () => {
                    if (
                      $scope.pagination.current <
                      $scope.pagination.total() - 1
                    )
                      $scope.pagination.current++;
                  },
                  total: () => {
                    const filteredArray = $filter("filter")(
                      $scope.list,
                      $scope.filter
                    );
                    return Math.ceil(
                      filteredArray.length / $scope.pagination.perPage
                    );
                  },
                };

                $scope.select = function (obj) {
                  obj.selected = !obj.selected;
                };

                $scope.cancel = function () {
                  $scope.$dismiss("cancel");
                };

                $scope.canOk = () => {
                  let selected = $scope.list.find((r) => r.selected);
                  return selected != null;
                };

                $scope.ok = () => {
                  let selected = $scope.list.filter((r) => r.selected);
                  $scope.$close(selected);
                };
              },
            ],
          }).result;
        },
        field: (fields) => {
          return $modal.open({
            keyboard: true,
            backdrop: "static",
            template: require("./templates/field.html"),
            controller: [
              "$scope",
              ($scope) => {
                if (!Array.isArray(fields)) {
                  throw new Error("Fields must be an array object");
                }
                $scope.fields = angular.copy(fields);
                $scope.ok = () => {
                  $scope.$close($scope.fields.map((r) => r.data));
                };
                $scope.cancel = () => {
                  $scope.$dismiss();
                };
              },
            ],
          }).result;
        },
      };
    },
  ])
  .run([
    "$rootScope",
    ($rootScope) => {
      $rootScope.uiModules = $rootScope.uiModules || [];
      let properties = {
        name: "Dialogs",
        description: "Dialog & Bottom Sheets",
        version: "1.0.2",
      };
      if ($rootScope.uiModules.indexOf(properties) === -1) {
        $rootScope.uiModules.push(properties);
      }
    },
  ]).name;
