export function routes($stateProvider) {
  $stateProvider
    .state('app', {
      template: require('./layout/layout.view.html'),
      controller: 'LayoutController',
      controllerAs: 'vm'
    })
    .state('public', {
      url: '/public',
      abstract: true,
      template: require('./layout/public.view.html'),
    })

    .state('auth', {
      template: require('./layout/main.view.html'),
    })
    .state('app.dashboard', {
      template: require('./dashboard/view.html'),
      url: '/',
      title: 'Dashboard',
      controller: 'DashboardController',
      controllerAs: 'vm'
    })
    .state('app.profile', {
      template: require('./profile/profile.view.html'),
      url: '/profile?link',
      title: 'Perfil',
      controller: 'ProfileController',
      controllerAs: 'vm',
      role: ['$authenticated']
    })
    .state('app.changelog', {
      url: '/changelog',
      abstract: true,
      template: '<ui-view></ui-view>'
    })
    .state('app.changelog.list', {
      template: require('./changelog/view.html'),
      url: '/',
      controller: 'ChangelogController',
      controllerAs: 'vm'
    })
    .state('app.changelog.create', {
      template: require('./changelog/create/view.html'),
      url: '/:id',
      controller: 'ChangelogController',
      controllerAs: 'vm'
    });
}

routes.$inject = ['$stateProvider'];
